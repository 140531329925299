import { Grid, Typography, ButtonBase, Box, Avatar } from "@material-ui/core";
import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";

import ReceiptLogo from "../../../assets/receipt_long.webp";
import useStyles from "./styles";
import {
  formatNum,
  formattedDateWithTime,
  formattedDate,
} from "../../../constants";

const TransactionsContainer = ({
  transaction,
  isSharedLedger,
  titleAvatar,
  sharedCustomerAvatar,
  imgUrl,
  index,
  transactions,
  fromStatement,
  setShowModal,
  openModal,
}) => {
  const styles = useStyles();
  const transactionBal = transactions;

  //It can only be undefined if we are in a private ledger so in that case, the avatar and emoji won't show anyway
  const createdByOwner = transaction?.user_id === transaction?.created_by;

  const getBalance = (transaction, index) => {
    if (index === 0 && transaction?.transaction_type === "debit") {
      transactionBal[index]["balance"] = -transaction?.amount;
      return -transaction?.amount;
    } else if (index === 0 && transaction?.transaction_type === "credit") {
      transactionBal[index]["balance"] = transaction?.amount;
      return transaction?.amount;
    } else if (index !== 0 && transaction?.transaction_type === "credit") {
      transactionBal[index]["balance"] =
        transactionBal[index - 1]?.balance + transaction?.amount;
      return transactionBal[index]["balance"];
    } else if (index !== 0 && transaction?.transaction_type === "debit") {
      transactionBal[index]["balance"] =
        transactionBal[index - 1]?.balance - transaction?.amount;
      return transactionBal[index]["balance"];
    }
  };

  const getDayTag = (transaction, index) => {
    transactionBal[index]["creation_day"] = formattedDate(
      transaction?.transaction_timestamp
    );
    if (index === 0) {
      return formattedDate(transaction?.transaction_timestamp);
    } else if (index !== 0) {
      if (
        transactionBal[index - 1]["creation_day"] ===
        formattedDate(transaction?.transaction_timestamp)
      ) {
        return "";
      } else {
        transactionBal[index]["creation_day"] = formattedDate(
          transaction?.transaction_timestamp
        );
        return formattedDate(transaction?.transaction_timestamp);
      }
    }
  };

  return (
    <>
      <Grid direction="column" container className={styles.container}>
        {getDayTag(transaction, index) !== "" ? (
          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            style={{ paddingTop: "32px" }}
          >
            <div className={styles.dayTag}>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "12px",
                  justifyContent: "center",
                  alignSelf: "center",
                  fontFamily: "GilmerMedium",
                }}
              >
                {getDayTag(transaction, index)}
              </Typography>
            </div>
          </Grid>
        ) : null}

        {transaction?.transaction_type === "credit" ? (
          <Grid
            container
            item
            justifyContent="flex-start"
            className={styles.transactionContainer}
          >
            {isSharedLedger && (
              <Avatar className={styles.avatar}>
                {imgUrl && createdByOwner ? (
                  <img src={imgUrl} width="24" height="24" alt="avatar" />
                ) : createdByOwner ? (
                  titleAvatar
                ) : (
                  sharedCustomerAvatar
                )}
              </Avatar>
            )}
            <ButtonBase
              className={styles.transactionContDebit}
              onClick={() => openModal(transaction)}
            >
              <Grid container item justifyContent="flex-start">
                <div className={styles.transactionAmountBillCont}>
                  <Typography className={styles.transactionAmountText}>
                    Rs {formatNum(Math.abs(transaction?.amount))}
                  </Typography>
                  {transaction?.invoice_id ? (
                    <div className={styles.logoContainer}>
                      <img
                        src={ReceiptLogo}
                        width={"32px"}
                        height={"32px"}
                        alt="Receipt"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {transaction?.note ? (
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="space-between"
                    className={styles.transactionNoteCont}
                  >
                    <Typography
                      align="left"
                      className={styles.transactionNoteText}
                    >
                      {transaction?.note}
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                <div className={styles.transactionContBorder}></div>
                <div className={styles.transactionTimeStampCont}>
                  <div className={styles.balanceContainer}>
                    <div className={styles.netBalance}>
                      <Typography className={styles.netBalanceText}>
                        {`Bal. Rs. ${formatNum(
                          getBalance(transaction, index)
                        )}`}
                      </Typography>
                    </div>
                  </div>
                  <div className={styles.timeContainer}>
                    <Typography className={styles.transactionTimeStamp}>
                      {`${formattedDateWithTime(
                        transaction?.transaction_timestamp,
                        fromStatement
                      )}`}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </ButtonBase>
            {isSharedLedger && createdByOwner && (
              <Box className={styles.reactionIcon}>
                <AddReactionOutlinedIcon
                  color="action"
                  fontSize="small"
                  onClick={() => setShowModal(true)}
                />
              </Box>
            )}
          </Grid>
        ) : (
          <Grid
            container
            item
            justifyContent="flex-end"
            className={styles.transactionContainer}
          >
            <ButtonBase
              className={styles.transactionContCredit}
              onClick={() => openModal(transaction)}
            >
              <Grid container item justifyContent="flex-start">
                <div className={styles.transactionAmountBillCont}>
                  <Typography className={styles.transactionAmountdebitText}>
                    Rs {formatNum(Math.abs(transaction?.amount))}
                  </Typography>
                  {transaction?.invoice_id ? (
                    <div className={styles.logoContainer}>
                      <img
                        src={ReceiptLogo}
                        width={"32px"}
                        height={"32px"}
                        alt="Receipt"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {transaction?.note ? (
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="space-between"
                    className={styles.transactionNoteCont}
                  >
                    <Typography
                      align="left"
                      className={styles.transactionNoteText}
                    >
                      {transaction?.note}
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                <div className={styles.transactionContBorder}></div>
                <div className={styles.transactionTimeStampCont}>
                  <div className={styles.balanceContainer}>
                    <div className={styles.netBalanceCredit}>
                      <Typography className={styles.netBalanceTextCredit}>
                        {`Bal. Rs. ${formatNum(
                          getBalance(transaction, index)
                        )}`}
                      </Typography>
                    </div>
                  </div>
                  <div className={styles.timeContainer}>
                    <Typography className={styles.transactionTimeStamp}>
                      {`${formattedDateWithTime(
                        transaction?.transaction_timestamp,
                        fromStatement
                      )}`}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </ButtonBase>
            {isSharedLedger && (
              <Box className={styles.reactionIconWithAvatar}>
                <Avatar className={styles.avatarDebit}>
                  {imgUrl && createdByOwner ? (
                    <img src={imgUrl} width="24" height="24" alt="avatar" />
                  ) : createdByOwner ? (
                    titleAvatar
                  ) : (
                    sharedCustomerAvatar
                  )}
                </Avatar>
                {createdByOwner && (
                  <AddReactionOutlinedIcon
                    color="action"
                    fontSize="small"
                    onClick={() => setShowModal(true)}
                  />
                )}
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default TransactionsContainer;
