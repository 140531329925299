import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    width: "100%",
    height: "30%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 10,
  },
  modalWrapper: {
    backdropFilter: "blur(0px)",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  paddingBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "150px",
  },
  hisabPointing: {
    alignSelf: "center",
    width: "140px",
  },
  playStoreLogoContainer: {
    display: "flex",
    width: "170px",
    flexDirection: "column",
    justifyContent: "center",
  },
  normalFont: {
    fontSize: "12px",
    padding: "10px 16px",
    textAlign: "center",
  },
  creditBookLogoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "14px",
  },
}));

export default useStyles;
