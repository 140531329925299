import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: "16px",
    backgroundColor: "#F6F4F0",
  },
  youGotButton: {
    justifyContent: "flex-start",
    backgroundColor: "#FFFFFF",
    color: "#626262",
    textTransform: "capitalize",
    flex: 0.9,
    height: "48px",
    fontSize: "14px",
    fontWeight: "400",
    borderRadius: "100px",
  },
  microphoneButton: {
    width: "48px",
    height: "48px",
    borderRadius: "24px",
    backgroundColor: "#267755",
    color: "#FFFFFF",
  },
}));

export default useStyles;
