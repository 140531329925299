import { makeStyles } from "@material-ui/core/styles";
import { themeColors } from "../../../constants";
const useStyles = makeStyles((theme) => ({
  container: {
    background: "#F6F4F0",
    padding: 0,
    margin: 0,
  },
  transactionContainer: {
    // backgroundColor: "#F6F4F0",
    padding: 0,
  },
  transactionAmountBillCont: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "13px 10px 10px 10px",
    paddingBottom: "7px",
  },
  logoContainer: {
    padding: "5px",
    paddingBottom: "2px",
    borderRadius: "4px",
    backgroundColor: "#F5F5F5",
  },
  transactionContCredit: {
    boxShadow: "3px 3px 15px -8px rgba(128,126,128,1)",
    borderColor: themeColors.whiteColor,
    borderWidth: 1,
    backgroundColor: "#FFFFFF",
    borderStyle: "solid",
    borderRadius: 7,
    margin: "10px",
    [`${theme.breakpoints.down("sm")}  `]: {
      width: "70%",
    },
    maxWidth: "70%",
  },
  reactionIcon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingBottom: "15px",
    marginRight: "10px",
  },
  avatar: {
    marginTop: "12px",
    marginLeft: "12px",
    backgroundColor: "#D3D3D3",
    padding: "2px",
    width: "22px",
    height: "22px",
  },
  avatarDebit: {
    marginTop: "12px",
    backgroundColor: "#D3D3D3",
    padding: "2px",
    width: "22px",
    height: "22px",
  },
  reactionIconWithAvatar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "15px",
    marginRight: "10px",
  },
  transactionContDebit: {
    boxShadow: "3px 3px 15px -8px rgba(128,126,128,1)",

    borderColor: themeColors.whiteColor,
    borderWidth: 1,
    borderStyle: "solid",
    backgroundColor: "#FFFFFF",
    borderRadius: 7,
    margin: "10px",
    [`${theme.breakpoints.down("sm")}  `]: {
      width: "70%",
    },
    maxWidth: "70%",
  },
  transactionAmountText: {
    color: themeColors.amountPay,
    fontSize: "24px",
    lineHeight: "15px",
    fontWeight: "500",
    fontFamily: "GilmerBold",
  },
  transactionAmountdebitText: {
    color: themeColors.amountDue,
    fontSize: "24px",
    lineHeight: "17px",
    fontWeight: "500",
    fontFamily: "GilmerBold",
  },
  transactionNoteCont: {
    padding: "10px 0px 0px 10px",
    paddingTop: "0px",
  },
  transactionContBorder: {
    width: "100%",
    border: "1px solid #DBDBDB",
  },
  transactionNoteText: {
    fontSize: "12px",
    color: "#626262",
    [`${theme.breakpoints.down("sm")}  `]: {
      width: "70%",
    },
    maxWidth: "80%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "20px",
    whiteSpace: "nowrap",
  },

  transactionTimeStampCont: {
    display: "flex",
    padding: "7px",
    flex: 1,
    paddingBottom: "4px",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  transactionTimeStamp: {
    color: themeColors.transactionTimeStampText,
    fontSize: "12px",
    fontWeight: "400",
  },
  balanceContainer: {
    display: "flex",
    flex: 1,
  },
  netBalance: {
    backgroundColor: "#EBF7F2",
    borderRadius: "4px",
    padding: "5px",
    paddingBottom: "3px",
  },
  netBalanceCredit: {
    backgroundColor: "#FBECEE",
    borderRadius: "4px",
    padding: "5px",
    paddingBottom: "3px",
  },
  timeContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  netBalanceText: {
    color: "#39B37F",
    fontSize: "11px",
    fontWeight: "600",
  },
  netBalanceTextCredit: {
    color: "#D73D51",
    fontSize: "11px",
    fontWeight: "600",
  },
  transactionImage: {
    height: 0,
  },
  cloudIcon: {
    marginLeft: "5px",
    fontSize: 15,
    color: "#4fc3f7",
  },
  dayTag: {
    height: "24px",
    width: "102px",
    backgroundColor: "#DBFBFF",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
  },
}));

export default useStyles;
