import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  modalContainer: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1,
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    padding: "15px",
    width: "305px",
    borderRadius: "8px",
    p: 4,
  },
  headerTitle: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#1C1C1C",
    fontFamily: "GilmerMedium",
  },
  headerSubtitle: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#626262",
    fontFamily: "GilmerMedium",
  },
  headerBottomBorder: {
    width: "100%",
    marginTop: "0.5rem",
    borderRadius: "4px",
    border: "1px solid #DBDBDB",
  },
  transactionDetailContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "1rem",
  },
  Rs: {
    display: "flex",
    flex: 0.3,
    alignItems: "center",
  },
  transactionDetail: {
    display: "flex",
    flexDirection: "column",
    flex: "1.7",
    justifyContent: "space-between",
  },
  transactionDetailHeading: {
    color: "#1F1F1F",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    marginLeft: "5px",
    fontFamily: "GilmerMedium",
  },
  transactionDetailValue: {
    color: "#01A27F",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    marginLeft: "5px",
  },
  transactionDetailValueDebit: {
    color: "#D22E2F",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    marginLeft: "5px",
  },
  transactionDateValue: {
    color: "#83949E",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    marginLeft: "5px",
  },
  buttonContainer: {
    display: "flex",
    flex: 1,
    marginTop: "1rem",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  okayButtonContainer: {
    display: "flex",
    width: "40%",
    padding: "10px",
    backgroundColor: "#267755",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center",
  },
  okayButtonContainer1: {
    display: "flex",
    width: "40%",
    padding: "10px",
    marginRight: "1rem",
    backgroundColor: "#E9F1EE",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center",
  },
  okayButtonText: {
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "12px",
  },
  okayButtonText1: {
    color: "#267755",
    fontWeight: 500,
    fontSize: "12px",
  },
}));
