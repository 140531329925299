export default {
    LOGIN_VERIFICATION_CODE_TEXT: "ھڪ تصديقي ڪوڊ موڪليو آھي ھن تي",
    LOGIN_COUNTRY_TEXT: "ملڪ چونڊيو / پاڪستان (92+)",
    LOGIN_PHONENUMBER: "فون نمبر ",
    WELCOME: "خوش آمديد",
    LOGIN: "لاگ ان",
    CHANGE_LANGUAGE: "زبان تبديل ڪيو",
    SELECT_LANGUAGE: "زبان/ٻولي چونڊيو",
    ADD_ACCOUNT: "نؤن اڪاؤنت شامل ڪیو",
    PAY: "ادا ",
    DUE: "بقايا",
    CLEAR: "ڊاھيو",
    PAID: "ادائيگي ٿي چڪي",
    ADDED_ON: " کي شامل ڪيو ",
    TOOK_LOAN: "جو قرض ورتو",
    RUPEES: "روپيا",
    SEARCH_CONTACTS: "اڪاؤنٽ‎ ڳوليو",
    SALAM: "سلام",
    NEW_CONTACT: "نؤن اڪاؤنٽ‎",
    GIVE_PAYMENT: "توهان ڏنا",
    RECEIVE_PAYMENT: "توهان ورتا",
    CHOOSE_YOUR_LANGUAGE: "پنھجي زبان چونڊيو",
    NEXT: "اڳيان",
    BACK: "پويان",
    VERIFICATION_CODE: " تصديقي ڪوڊ ",
    A_verification_code_will_be_sent_on_your_number_via_SMS_Service_charges_may_apply: "تصديقي ڪوڊ تونھانجي نمبر تي موڪلیو ويندو. ايس ايم ايس جي رقم لڳندي",
    DIDNT_GET_CODE: "تصديقي ڪوڊ ن مليو ؟",
    RESEND_CODE: "ڪوڊ ٻيھر موڪليو",
    ENTER_MOBILE_NUMBER: "موبايل نمبر لکو",
    PAKISTAN_92: "پاڪستان (92+)",
    PERSONAL_INFORMATION: "ذاتي معلومات",
    GET_STARTED: "شروع ڪيو",
    LOGOUT: "لاگ آؤٹ",
    HOME: "ھوم",
    ADD: "ایڈ",
    NO_ACCOUNT_FOUND_NAMED: "ھن نالي جو ڪو ب اڪاؤنت ناھي",
    CONTACTS_FROM_PHONEBOOK: "فون بوڪ جا رابطا",
    SETTINGS: "سیٹنگز",
    ABOUT_US: "اسان جي باري م",
    PRIVACY_POLICY: "راز داري جي پاليسي",
    HELP: "مدد",
    CONTACT: "رابطو",
    SORT_NAME: "نالو",
    SORT_AMOUNT: "ر‌‍قم",
    SORT_LATEST: "تازه ترين",
    SORT_PAYMENT_DUE: "مقرر ادائيگي",
    NAME: "نالو",
    PHONE_NUMBER: "فون نمبر",
    BUSINESS_NAME: "ڪاروبار جو نالو",
    TODAY: "اڄ",
    YESTERDAY: "ڪلھ",
    ENTER_AMOUNT: "رقم وجھو",
    WRITE_NOTE_HERE: "ھتي نوٽ لکو",
    FULL_NAME: "پورو نالو",
    EMAIL_ADDRESS: "اي ميل ايڊريس",
    BUSINESS_TYPE: "ڪاروبار جو قسم",
    TRANSACTION_INFO: "ڏي وٺ جي  معلومات",
    SYNC_SUCESSFULLY: "اپڊيٽ ڪامياب",
    NOT_SYNC: "اپڊيٽ ناڪامياب",
    SHARE_TRANSACTION: "ڏي وٺ (جي  معلومات) ورھايو",
    DELETE_TRANSACTION: "ڏي وٺ (جي  معلومات) ڊيليٽ‎ ڪيو",
    NOTE_NOT_SPECIFIED: "نوٽ‎ واضح ن آھي",
    ADVANCE: "ايڊوانس",
    ACCOUNT: "اڪاؤنٽ‎",
    WHATSAPP: "واٽس ايپ",
    SHARE: "شئیر",
    VERSION: "نسخ/ورذن",
    UPDATE: "اپڊيٽ‎",
    UPDATE_ACCOUNT: "اڪاؤنٽ‎ اپڊيٽ‎",
    ACCOUNTS: "اڪاؤنٽ‎",
    USER_STATEMENT: "صارف اسٹیٹمنٹ",
    PROFILE: "پروفايل",
    LANGUAGE: "زبان",
    BALANCE: "بيلنس",
    NOTE: "نوٽ‎",
    DELETE_ACCOUNT: "اڪاؤنٽ ڊيليٽ",
    NET_BALANCE: "ڪُل بيلنس",
    CREDITS: "ڪريڊٿ",
    PAYMENTS: "ادايگيون",
    DO_YOU_WANT_TO_DELETE_THIS_TRANSACTION: "ڇا توھان کي ھي ڏي وٺ کي ڊيليت ڪرڻو آھي ؟",
    TRANSACTION_HAS_BEEN_DELETED: "ڏي وٺ ڊيليٽ‎ ٿي چڪي",
    RESTORE_TRANSACTION: "ڏي وٺ بحال ڪيو",
    DO_YOU_WANT_TO_RESTORE_THIS_TRANSACTION: "ڇا توھان ڏي وٺ کي بحال ڪرڻ چاھيو ٿا؟",
    TRANSACTION_HAS_BEEN_RESTORED: "ڏي وٺ بحال ٿي چڪي",
    VERIFIED_BY_CREDIT_BOOK: "ڪريڊت بوڪ مان تصديق ٿيل",
    AMOUNT_GIVEN: "ڏنل رقم",
    AMOUNT_RECEIVED: "ورتل رقم",
    PLEASE_ENTER_AMOUNT: "مھرباني ڪري رقم داخل ڪيو",
    PLEASE_ENTER_MOBILE_NUMBER: "مھرباني ڪري موبايل نمبر داخل ڪيو",
    PLEASE_CHECK_INTERNET_CONNECTION: "مھرباني ڪري انٽرنيٽ ڪنيڪشن چيڪ ڪيو",
    SOMETHING_WENT_WRONG: "اڙي! ڪجھ خرابي آھي",
    CODE_RESENT: "ڪوڊ اکر بيھر موڪليو آھي",
    UPDATE_NOTE: "اپڊيٽ‎ نوٽ‎",
    ADD_CUSTOMER: "ايڊ صارف/گراھڪ",
    SEARCH_CUSTOMERS: "تلاش صارف/گراھڪ",
    NEW_CUSTOMER: "نؤن صارف/گراھڪ",
    NO_CUSTOMER_FOUND_NAMED: "صارف/گراھڪ ن مليو",
    UPDATE_CUSTOMER: "اپڊيٽ‎ صارف/گراھڪ",
    DELETE_CUSTOMER: "ڊليٽ‎ صارف/گراھڪ",
    CUSTOMERS: "گراھڪ",
    SEND_REMINDER: "يادگيري ڏيارڻ/ ياد ڏيارڻ",
    SHARE_STATEMENT: "شیئر اسٹیٹمنٹ",
    CREDIT_LIMIT: "ڪريڊٽ جي حد",
    CONTACT_US: "اسان سان رابطو",
    ADD_FROM_PHONEBOOK: "فون بوڪ مان ايڊ",
    SEARCH_FROM_PHONEBOOK: "فون بوڪ مان تلاش",
    SORT_TYPE: "تازه ترين",
    NO_TRANSACTIONS_AVAILABLE: "ڪا ب ڏي وٺ موجود ناھي",
    TRANSACTION_MESSAGE_WHEN_GIVING: "توھان ‍قرض ورتو آھي Rs:{رقم}, {دڪان نالو} کان, {تاريخ} تي, ھي موڪليو ويو آھي ڪريڊٽ بوڪ مان. ھينُر ڪريڊٽ بوڪ ڊاؤنلوڊ ڪيو اءين پنھنجو کاتو مؤصر طريقي سان سنڀاليو. ",
    TRANSACTION_MESSAGE_WHEN_TAKING: "توھان ‍Rs:{رقم} ڏني آھي, {دڪان نالو} کي, {تاريخ} تي. ھي موڪليو ويو آھي ڪريڊٽ بوڪ مان. ھينُر ڪريڊٽ بوڪ ڊاؤنلوڊ ڪيو اءين پنھنجو کاتو مؤصر طريقي سان سنڀاليو.",
    SHARE_MSG: "ھينُر ڪريڊٽ بوڪ ڊاؤنلوڊ ڪيو اءين پنھنجو کاتو مؤصر طريقي سان سنڀاليو. http://onelink.to/z8xrde",
    REMINDER_WHEN_CREDIT: "توھانجو ڪريڊٽ ‎ Rs:{اڪاؤنٽ بيلينس} رھي ٿو {دڪان نالو} وٽ. ھينُر ڪريڊٽ بوڪ ڊاؤنلوڊ ڪيو اءين پنھنجو کاتو مؤصر طريقي سان سنڀاليو. http://tiny.cc/rmmsmz",
    REMINDER_WHEN_BALANCE: "توھانجو اڪاؤنٽ بيلينس Rs:{اڪاؤنٽ . بيلينس}, {دڪان نالو} وٽ‎. ھينُر ڪريڊٽ بوڪ ڊاؤنلوڊ ڪيو اءين پنھنجو کاتو مؤصر طريقي سان سنڀاليو. http://tiny.cc/rmmsmz",
    CONTACT_US_MESSAGE: "سلام ! مونکي مد د گھرجي ڪريڊٽ بوڪ ايپ جي استحمال مي",
    CHANGE_LANGUAGE_CONFIRMATION: "ھن خصوصيت لاً توھان کي ايپ بند ڪري ٻيھر کولڻو پوندو۔ ڇا توھان ڪرڻ چاھيو ٿا ؟",
    USER_CONSENT_MESSAGE: "ساۂن اپ ڪري توھان اسانجي شرطن و ضابطن ء رازداري جي پاليسي سان اتفاق ڪيو ٿا",
    START_DATE: "تاریخ شروع",
    END_DATE: "تاریخ ختم",
    THIS_MONTH: "ھي مھينو",
    CUSTOM_RANGE: "پنھنجي مرضي جي مطابق",
    CREDIT_LIMIT_EXCEED_MSG: "ڪريڊٿ حد کان تجاوز, ڇا توھان جاري رکڻ پسند ڪندؤ؟",
    WARNING: "خبردار",
    INVALID_MOBILE_NO_ERROR: "غلط موباۂيل # صحيح فارميٽ : 03451234567",
    FIELD_REQUIRED: "فيلڊ گھرجي",
    CREDIT_LIMTI_CANT_BE_ZERO: "ڪريڊٽ جي حد ٻڙي(0) نٿي ٿي سگھی, (اگر توھان حد مقر ر ڪرڻ ڇاھيو ٿا ت ھن کي خالي ڇڏي ڏيو)",
    DO_YOU_WANT_TO_DELETE_THIS_CUSTOMER: "ڇا توھان ھن صارف کي ڊيليٽ‎ ڪرڻ چاھيو ٿا؟",
    ENTER_YOUR_NOTE_HERE: "پنھنجو اڪاؤنٽ‎ ھتي داخل ڪيو",
    ADD_YOUR_FIRST_CUSTOMER: 'پنهنجو پهريون صارف داخل ڪيو',
    PRESS_THE_BUTTON_FOR_ADDING_YOUR_FIRST_CUSTOMER: "پنهنجي پهرين صارف کي ايڊ ڪرڻ جي لاءِ بٽن کي دٻايو",
    GO_TO_DASHBOARD: "ڊيشبورڊ تي وڃو",
    TOOLTIP_TEXT_ONBOARDING_WHEN_ADDED_FIRST_CUSTOMER: "توهان پنهنجو پهريون صارف درج ڪري ڇڏيو آهي!پئسن جي وصولي يا ادائيگي جي لاءِ صارف تي ڪلڪ ڪيو",
    TOOLTIP_TEXT_ONBOARDING_WHEN_ADDED_FIRST_TRANSACTION: 'توهان ڏي وٺ درج ڪري ڇڏي آهي! ڪريڊٽ بُڪ ۾ ٻيون به خصوصيتون موجود آهن. وڌيڪ معلومات لاءِ "؟" آئيڪن تي ڪلڪ ڪيو مددگار وڊيوز ڏسڻ لاءِ. ',
    REMINDER_HAS_BEEN_SENT: "يادگيري ڪرائي ڇڏي!",
    MOBILE_NO_NOT_ADDED: "موبائل نمبر موجود نه آهي",
    MOBILE_NO_NOT_ADDED_DESCRIPTION: " جو موبائل نمبر موجود نه آهي. ڇا توهان موبائل نمبر درج ڪرڻ پسند ڪندؤ؟ ",
    SOMETHING_WENT_WRONG_WHILE_SENDING_SMS: "SMS موڪلڻ وقت ڪا خرابي آئي آهي. مهرباني ڪري ٻيهر ڪوشش ڪيو!!",
    REPORTS: "رِپورٽون",
    REMINDERS: "يادگيري",
    PAYMENT_DUE_DATE: "ادائيگيءَ جي آخري تاريخَ",
    ACOUNT_CALL: 'ڪال',
    ACCOUNT_PAYMENT_REMINDER_DATE_SET: 'ادائيگيءَ جي يادگيريءَ جي تاريخَ درج ڪيو',
    AUTO_REMINDER: "پاڻمُرادو يادگيري",
    REMINDER_DATE: "یادہانی کی تاریخ",
    FREE_SMS: "مُفت ايس ايم ايس",
    REMIND_ALL: "سڀني کي ياد ڏياريو",
    REMIND: "ياد ڏياريو",
    REMINDER_SENT: "يادگيري موڪلي ڇَڏِي",
    SMS_ABOUT_TO_SEND_ALL: "توهان ايس ايم ايس موڪلڻ وارا آهيو، سڀني انهن صارفن ڏي جن کي بَقايا رَقَمَ ڏيڻي آهي!ڇا توهان جاري رکڻ چاهيندوء؟ نوٽ: ايس ايم ايس صرف انهن صارفن ڏي موڪليو ويندو جن جو نمبر درج آهي.",
    APPLY_FILTERS: "فلٽرز هلايو",
    LOWEST_AMOUNT: "گهٽ ۾ گهٽ رَقَمَ",
    HIGHEST_AMOUNT: "وڌ ۾ وڌ رَقَمَ",
    SORT_BY: "ترتيب بَذَريعو",
    AMOUNT: "رَقَمَ",
    ADD_FILTERS: "فِِلٽرز لڳايو",
    TRANSACTIONS_ALERT_SETTING: "ڏي وٺ جي اطلاع سيٽنگ",
    TRANSACTION_ALERTS: "ڏي وٺ اطلاعون",
    TRANSACTION_TOGGLE_MESSAGE: "ڏي وٺ جون اطلاعون ايس ايم جي ذريعي توهان جي صارف ڏي وينديون آهن جڏهن به ڏي وٺ عمل ۾ ايندي آهي",
    DATA_SECURITY_MESSAGE: "توهان جو ڊيٽا ۽ ڏي وٺ مڪمل طور تي محفوظ آهن.",
    AUTO_REMINDER_MESSAGES: "روز صبح جو6:00 بجي يادگيري پاڻمرادو موڪلي ويندي آهي",
    SCHEDULED_FOR_LATER: "پوءِ جي لاءِ مقرر",
    TO_BE_SENT: "موڪلڻو آهي ",
    DEBITS: "ڊيبٽ",
    APPLOCK: "ايپ لاڪ",
    CREATE_A_NEW_PIN: "نئون پن ٺاهيو",
    CONFIRM_PIN: "تصدیق پن",
    PIN_NOT_MATCHED_ERROR: "پن ۾ يڪسانيت نه آهي مهرباني ڪري ٻيهر درج ڪيو",
    APPLOCK_ENABLED: "ايپ لاڪ لڳائي ڇڏيو",
    APP_LOCK_UPDATED: "ايپ لاڪ اپڊيٽڊ",
    REMOVE_APPLOCK: "ايپ لاڪ هٽايو",
    CHANGE_PIN: "پن تبدیل",
    RE_ENTER_YOUR_PIN: "پن ٻيهر درج ڪيو",
    REMOVE_PIN: "پن هٽايو",
    INVALID_PIN_ERROR: "غلط پن، مهرباني ڪري ٻيهر درج ڪيو",
    APP_LOCK_REMOVED: "ايپ لاڪ هٽي ويو",
    ENTER_YOUR_OLD_PIN: "پراڻو پن درج ڪيو",
    ENTER_YOUR_PIN: "پن درج ڪيو",
    CONTACT_SUPPORT: "رابطو ڪيو مدد جي لاءِ",
    FORGOT_YOUR_PIN: "پن وسري ويو؟",
    CLICK_HERE: "هتي ڪلڪ ڪيو",
    DATA_BACKUP: "ڊيٽا بيڪ اپ",
    YOUR_DATA_HAS_BEEN_BACKED_UP: "توهانجو ڊيٽا بيڪ اپ ٿي ويو!",
    DATA_BACKUP_DESC: "انٽرنيٽ سان ڪنيڪٽ ڪيو پنهنجي ڊيٽا کي اسان جي سروس تي بيڪ اپ ڪرڻ لاءِ",
    LAST_BACKUP_AT: "آخري بيڪ اپ ٿيو!",
    SMS_ALERT_LANGUAGE: "ايس ايم ايس الرٽ زبان",
    TRANSACTIONAL_SMS_SAMPLE: "ڏيڻ وٺڻ جي ايس ايم ايس جي مثال",
    CONFIRM_LANGUAGE: "زبان ڪنفرم",
    TOTAL: "ڪُل",
    CUSTOMER_STATEMENT: "صارف اسٽيٽمينٽ",
    ALL: "سڀ",
    BUSINESS_CARD: "بزنس ڪارڊ",
    SAMPLE_CARD_DESIGN: "عام ڪارڊ ڊيزائين",
    CREATE_A_NEW_BUSINESS_CARD: "نئون بزنس ڪارڊ ٺاهيو",
    ALTERNATE_PHONE_NUMBER: "متبادل فون نمبر",
    LOCATION: "لوڪيشن",
    AUTOMATICALLY_PICKS_UP_CURRENT_LOCATION_SHOWN_IN_GOOGLE_MAP_BELOW: "هيٺ ڏنل لوڪيشن پاڻ ئي گوگل ميپ جي ذريعي چونڊي وٺندو",
    SAVE_CARD: "سيو ڪارڊ",
    SHARE: "شيئر",
    DELETE: "ڊليٽ",
    EDIT: "ايڊٽ",
    CREATE_NEW_CARD: "نئون ڪارڊ ٺاهيو",
    COLLECT_PAYMENTS_3_TIMES_FASTER: "ادائيگي ۳ گنا تيزي سان حاصل ڪيو!",
    SET_COLLECTION_DATES: "تاريخ طي ڪيو حاصل ڪرڻ جي",
    REMINDERS_GO_OUT_1_DAY_BEFORE: "يادگيري هڪ ڏينهن پهرين ڪرائي ڇڏبي",
    COLLECT_PAYMENTS_3_X_FASTER: "ادائيگي 3X تيزي سان حاصل ڪيو",
    ADDRESS: "پته",
    BUSINESS_CARD_HAS_BEEN_CREATED: "بزنس ڪارڊ ٺهي ويو آهي!",
    BUSINESS_CARD_HAS_BEEN_UPDATED: "بزنس ڪارڊ اپڊيٽ ٿي ويو آهي!",
    DATE_RANGE: "ڊيٽا رينج",
    INVALID_AMOUNT: "غلط رقم",
    COLLECTION_DATE: "جمع ڪرڻ جي تاريخ",
    YOUR_DATA_IS_100_SAFE_AND_SECURE: "توهان جو ڊيٽآ 100% حفاظٽ ۽ محفوظ آهي",
    TRANSACTION_HAS_BEEN_UPDATED: "لين دين اپ ڊيٽ ٿي وئي",
    DONE: "ٿي ويو",
    AD_INSERTED_SUCCESS: "اشتھار لڳي ويو!",
    ONLINE_NOTE: "توهان آنلائن موڊ ۾ ايپ استعمال ڪري رهيا آهيو!",
    OFFLINE_NOTE: "توهان آفلآئن موڊ ۾ ايپ استعمال ڪري رهيا آهيو!",
    TITLE_IS_REQUIRED: "ٽائيٽل ضروري آهي!",
    AD_UPDATED_SUCCESS: "اشتهار اپڊيٽڊ!",
    CAROUSEL_DESC: "روز پنهنجي ڪاروباري اڪاوئنٽ کي اپڊيٽ ڪري پنهنجو ٽائيم بچايو. ڪسٽمر سان بعث ائين پيپرز ۾ ٽائيم ضايع ڪرڻ هاڻين بلڪل به نه.",
    EXISTING_CUSTOMER: "موجوده ڪسٽمر",
    ADD_A_STARTING_BALANCE: "ابتدائي بيلنس وجهو",
    STARTING_BALANCE: "ابتدائي بيلنس",
































}