import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  disableParent: {
    position: "fixed",
    top: 0,
    left: 0,
    background: "#ff989840",
    "z-index": 998,
    height: "100%",
    width: "100%",
  },

  overaly: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "z-index": "1000",
  },
}));

export default useStyles;
