import React, { FC } from "react";
import { Typography, Modal, Box } from "@material-ui/core";
import { useStyles } from "./styles";

interface ItemNotFoundModalProps {
  showModal: boolean;
  closeModal: any;
  title: string;
  description: string;
}

export const ItemNotFoundModal: FC<ItemNotFoundModalProps> = ({
  showModal,
  closeModal,
  title,
  description,
}) => {
  const styles: any = useStyles();

  return (
    <Modal
      open={showModal}
      onClose={() => closeModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.modalContainer}>
        <Typography className={styles.headerTitle} align="center">
          {title}
        </Typography>
        <div className={styles.headerBottomBorder}></div>
        <div className={styles.transactionDetailContainer}>
          <Typography className={styles.headerTitle}>{description}</Typography>
        </div>
        <div className={styles.transactionDetailContainer}></div>
        <div className={styles.buttonContainer}>
          <div
            onClick={() => closeModal()}
            className={styles.okayButtonContainer}
          >
            <Typography className={styles.okayButtonText}>Okay</Typography>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
