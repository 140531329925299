import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  SharedLedgerComponents: {
    paddingTop: "24px",
  },
  sharedLedgerFooter: {
    backgroundColor: "#F6F4F0",
  },
  normalFont: {
    fontSize: "12px",
    padding: "10px 16px",
    textAlign: "center",
  },
  footerSection: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },

  footerText: {
    marginTop: "2px",
    fontSize: "12px",
    fontWeight: "600",
    paddingLeft: "4px",
  },
  creditBookLogoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "24px",
  },
  playStoreLogoContainer: {
    width: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingRight: "16px",
  },
}));

export default useStyles;
