import { lightGreen } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles'
import { themeStyleSheet, themeColors } from "../../../constants";


const useStyles = makeStyles((theme) => ({

    contentContainer: {
        padding: "8px",
        display: "flex",
        justifyContent: 'flex-start',
        alignItems: "center",
        backgroundColor: "#F6F6F6",
        borderBottom: "0.5px",
        // borderRadius: 10,
        marginBottom: "2px"

    },
    innerCOntent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
    },
    avatar: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        marginRight: "6px",
        backgroundColor: "#D3D3D3"
    },
    payDueCont: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "5px",
        width: "85px",
        // border: "1px solid black"

    },
    avatarNameCont: {
        fontWeight: 500
    },



}))

export default useStyles