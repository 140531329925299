import { makeStyles } from '@material-ui/core/styles'
import { themeColors } from '../../../constants'

const useStyles = makeStyles((theme) => ({
    loanReceived: {
        color: "#7A7A7A",
        fontSize: 17,
        fontWeight: "bold",
        display: "flex",
    },
    price: {
        color: "#7A7A7A",
        fontSize: 17,
        fontWeight: "bold",
        display: "flex",
    },
    schedule: {
        color: "#989595",
        fontSize: 14,
        marginTop: -4,
        display: "flex",
    },
    leftCardWrapper: {
        background: "#E7F1F2",
        float: "left",
        minHeight: "100px",
        width: "200px",
        border: "1px solid rgba(144, 144, 144, 0.8)",
        borderRadius: "20px",
        padding: "15px",
        marginTop: "30px",
        // clear: both,
    },
    leftCardDetails: {
        justifuContent: "flex-start"

    },
    rightCardDetails: {
        justifuContent: props => props.type == "Credit" ? "flex-start" : "flex-end"
    },

    rightCardWrapper: {
        minHeight: "100px",
        width: "200px",
        border: "1px solid rgba(144, 144, 144, 0.8)",
        borderRadius: "20px",
        padding: "15px",
        marginTop: "30px",
        // clear: both,
        background: "#FAEDEE",
        float: "right"
    },
    dateTag: {
        color: "rgba(0,0,0,0.75)",
        fontSize: "14px",
        backgroundColor: "#D3E8E8",
        padding: "5px 13px",
        height: "max-content",
        marginTop: "10px",
        borderRadius: "4px",
    },

    transactionAlertDesc: {
        marginTop: "20px",
        marginBottom: "20px",
        backgroundColor: "#FFF5C4",
        padding: "10px 10px",
        height: "max-content",
        alignItems: "center",
        borderWidth: 1,
        [`${theme.breakpoints.down("sm")}  `]: {
            width: "80%",
        },
        width: "fit-content",
        borderColor: themeColors.transactionBorder,
        borderRadius: 7,
    },
    transactionAlertDescText: {
        fontSize: 14,
        color: themeColors.datePickerText,
        width: `calc(100% - 20px      )`,
        paddingLeft: "10px",
    },
    transactionIcon: {
        width: "20px",
        fontSize: 20,
        color: "rgba(0,0,0,0.75)",
    },


}))

export default useStyles