import React, { FC, useState } from "react";
import { Typography, Modal, Box } from "@material-ui/core";
import axios from "axios";
import Note from "../../../assets/Note.png";
import { formattedDate } from "../../../constants";
import { CustomerInvoiceModal } from "./CustomerInvoiceModal";
import Rs from "../../../assets/Rs.webp";
import Date from "../../../assets/Date.png";
import { useStyles } from "./styles";
import { ItemNotFoundModal } from "../ItemNotFoundModal";

interface transationalDetailsProps {
  showModal: boolean;
  closeModal: any;
  ownerMobileNumber: string;
  isStagingEnv: boolean;
  transaction?: any;
  customer?: any;
}

export const TransactionalDetailsModal: FC<transationalDetailsProps> = ({
  showModal,
  closeModal,
  isStagingEnv,
  transaction,
  customer,
  ownerMobileNumber,
}) => {
  const styles: any = useStyles();
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [openItemNotFoundModal, setOpenItemNotFoundModal] = useState(false);
  const [invoice, setInvoice] = useState({});
  let fromStatement = true;
  const apiUrl = isStagingEnv
    ? process.env.REACT_APP_API_URL_STAGE
    : process.env.REACT_APP_API_URL_PROD;

  const handleInvoice = () => {
    let URL = `${apiUrl}/api/v1/customer/invoice`;
    axios
      .get(URL, {
        params: {
          customer_id: customer?.firestore_id,
          invoice_id: transaction?.invoice_id,
        },
      })
      .then((res) => {
        setInvoice(res?.data?.invoice);
        setOpenInvoiceModal(true);
      })
      .catch((err) => {
        console.error("catch error", err);
        setOpenItemNotFoundModal(true);
      });
  };

  return (
    <Modal
      open={showModal}
      onClose={() => closeModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={openInvoiceModal ? {} : { backdropFilter: "blur(2px)" }}
    >
      <Box className={styles.modalContainer}>
        <CustomerInvoiceModal
          showModal={openInvoiceModal}
          isStagingEnv={isStagingEnv}
          closeModal={() => setOpenInvoiceModal(false)}
          invoice={invoice}
          ownerMobileNumber={ownerMobileNumber}
        />
        <ItemNotFoundModal
          showModal={openItemNotFoundModal}
          closeModal={() => setOpenItemNotFoundModal(false)}
          title={"Bill not found"}
          description={"The bill might have been deleted!"}
        />
        <Typography className={styles.headerTitle}>
          {transaction?.transaction_type === "credit"
            ? `${customer?.name}, aap ne liye hain Rs. ${transaction?.amount}`
            : `${customer?.name}, aap ne diye hain Rs. ${transaction?.amount}`}
        </Typography>
        <Typography className={styles.headerSubtitle}>
          Transaction Details
        </Typography>
        <div className={styles.headerBottomBorder}></div>
        <div className={styles.transactionDetailContainer}>
          <div className={styles.Rs}>
            <img width={16} height={11} src={Rs} alt="Rs" />
          </div>
          <div className={styles.transactionDetail}>
            <Typography className={styles.transactionDetailHeading}>
              Amount
            </Typography>
            <Typography
              className={
                transaction?.transaction_type === "credit"
                  ? styles.transactionDetailValue
                  : styles.transactionDetailValueDebit
              }
            >
              {transaction?.amount}
            </Typography>
            <div className={styles.headerBottomBorder}></div>
          </div>
        </div>
        <div className={styles.transactionDetailContainer}>
          <div className={styles.Rs}>
            <img width={18} height={20} src={Date} alt="Note" />
          </div>
          <div className={styles.transactionDetail}>
            <Typography className={styles.transactionDetailHeading}>
              Date
            </Typography>
            <Typography className={styles.transactionDateValue}>
              {formattedDate(transaction?.transaction_timestamp, fromStatement)}
            </Typography>
            <div className={styles.headerBottomBorder}></div>
          </div>
        </div>
        <div className={styles.transactionDetailContainer}>
          <div className={styles.Rs}>
            <img width={18} height={18} src={Note} alt="Note" />
          </div>
          <div className={styles.transactionDetail}>
            <Typography className={styles.transactionDetailHeading}>
              Note
            </Typography>
            <Typography className={styles.transactionDateValue}>
              {transaction?.note ? transaction?.note : "no notes"}
            </Typography>
            <div className={styles.headerBottomBorder}></div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <div
            className={
              transaction?.invoice_id
                ? styles.okayButtonContainer1
                : styles.okayButtonContainer
            }
            onClick={() => closeModal()}
          >
            <Typography
              className={
                transaction?.invoice_id
                  ? styles.okayButtonText1
                  : styles.okayButtonText
              }
            >
              Okay
            </Typography>
          </div>
          {transaction?.invoice_id ? (
            <div className={styles.okayButtonContainer} onClick={handleInvoice}>
              <Typography className={styles.okayButtonText}>
                Bill Dekhein
              </Typography>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Box>
    </Modal>
  );
};
