import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import CbLogo from "../../../assets/cb_logo_black.webp";
import PlayStoreLogo from "../../../assets/googleplay.png";

const TransactionalFooter = () => {
  const styles = useStyles();
  const playStoreUrl = "https://app.adjust.com/v1nfd8i";

  return (
    <section className={styles.footerSection}>
      <div className={styles.footerSection1}>
        <img src={CbLogo} alt="Cb-Logo" width={120} />
      </div>
      <div className={styles.footerSection2}>
        <Typography
          style={{ fontFamily: "GilmerMedium" }}
          className={styles.footerText}
        >
          Apna Khata
        </Typography>
        <Typography
          style={{ fontFamily: "GilmerMedium" }}
          className={styles.footerText}
        >
          Digital Banayen
        </Typography>
        <div className={styles.PlayStoreLogo}>
          <img
            width={72}
            src={PlayStoreLogo}
            alt="PlayStore-Logo"
            onClick={() => window.open(playStoreUrl)}
          />
        </div>
      </div>
    </section>
  );
};

export default TransactionalFooter;
