import useStyles from "./styles";
import { CreateTransactionButtons, DownloadCbModal } from "../../../components";
import { ChatContainer } from "../../../components";
import CbLogo from "../../../assets/cb_logo_black.webp";
import PlayStoreLogo from "../../../assets/googleplay.png";
import { Typography } from "@material-ui/core";
import { SegmentAnalytics } from "../../../utils";

const SharedLedgerFooter = ({
  ownerName,
  customerId,
  showModal,
  setShowModal,
}) => {
  const styles = useStyles();
  const playStoreUrl = "https://app.adjust.com/v1nfd8i";


  const clickDownloadCbApp = async () => {
    try {
      const response = await fetch('https://api.ipify.org/', {
        timeout: 2000, //2 second timeout
      });
      const ip = await response.text();
  
      SegmentAnalytics.track("click_downloadCbAppWebView", {
        ledgerOwnerName: ownerName,
        customerId,
      }, {
        ip,
      });
    } catch(err) {
      console.error(err);
    }

    window.open(playStoreUrl);
  };

  return (
    <div className={styles.sharedLedgerFooter}>
      <section className={styles.footerSection}>
        <div className={styles.logoContainer}>
          <div className={styles.creditBookLogoContainer}>
            <img src={CbLogo} alt="Cb-Logo" width={120} />
          </div>
          <div className={styles.playStoreLogoContainer}>
            <img
              src={PlayStoreLogo}
              alt="PlayStore-Logo"
              onClick={clickDownloadCbApp}
            />
          </div>
        </div>

        <Typography className={styles.normalFont}>
          {ownerName} ke sath khata chalanay keliye free mein <b>CreditBook</b>{" "}
          download karein.
        </Typography>
      </section>

      <div className={styles.SharedLedgerComponents}>
        <ChatContainer setShowModal={setShowModal} />
        <CreateTransactionButtons setShowModal={setShowModal} />
        {showModal && (
          <DownloadCbModal
            setShowModal={setShowModal}
            clickDownloadCbApp={clickDownloadCbApp}
          />
        )}
      </div>
    </div>
  );
};

export default SharedLedgerFooter;
