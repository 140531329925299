import { makeStyles } from "@material-ui/core/styles"
import empty from "../../../assets/empty.png";

const useStyles = makeStyles((theme) => ({
    imageTitleContainer: {
        backgroundColor: "#F6F6F6",
        width: "100%",
    },
    accountName: {
        fontSize: "25px",
        marginLeft: "18px",
        paddingBottom: "20px"
    },
    textFieldContainer: {
        display: "flex",
        flexDirection: "column",
        width: "80%",
        marginTop: "20px"
        // padding: "20px",
        // paddingLeft: "40px"
    },
    textField: {
        width: "100%",
        fontSize: "20px",
        fontSize: "30px",
        marginBottom: "20px"
    },
    sendMessage: {
        paddingLeft: "10px"

    },
    getDirections: {
        borderColor: "#F6F6F6",
        paddingBottom: "20px",
        borderBottom: "1px solid black",
        width: "100%"
    },
    callAction: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: "15px",
        paddingBottom: "10px",
        marginBottom: "10px",
        borderBottom: "2px solid #c5c5c5",
        width: "100%",
        cursor: "pointer"

    },
    backButton: {
        color: "white",
        fontSize: "30px",
        margin: "10px"
    },
    accountPic: {
        width: "100%",
        height: "25vh",
    },
    mapContainer: {
        height: "50vh",
        width: "80%",
        marginTop: "50px",
        marginBottom: "50px",
    }

}))

export default useStyles