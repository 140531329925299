import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import amp from "amplitude-js";
import { config } from "../config/firebaseConfig";

const deviceHeight = window.innerHeight;
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const formatNum = (num) => {
  return num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formattedDateWithTime = (
  timestamp,
  fromStatement,
  fromReportTrx
) => {
  let dateObj = new Date(
    fromStatement
      ? timestamp?._seconds
        ? timestamp?._seconds * 1000
        : timestamp
      : timestamp?.seconds
      ? timestamp?.seconds * 1000
      : timestamp
  );

  var hours = dateObj.getHours();
  var minutes = dateObj.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;

  return fromReportTrx ? strTime : strTime;
};
export const formattedDate = (timestamp, fromStatement, fromReportTrx) => {
  let dateObj = new Date(
    fromStatement
      ? timestamp?._seconds
        ? timestamp?._seconds * 1000
        : timestamp
      : timestamp?.seconds
      ? timestamp?.seconds * 1000
      : timestamp
  );
  let month = dateObj.getUTCMonth() + 1; //months from 1-12
  let day = dateObj.getUTCDate();
  let year = dateObj.getUTCFullYear();

  return fromReportTrx
    ? `${day}${nth(day)}` + " " + months[month - 1]
    : `${day}${nth(day)}` + " " + months[month - 1] + " " + year;
};

// this function adds nth,nd,st,rd to dates
const nth = function (d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export { deviceHeight };

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const analytics = firebase.analytics;
export const amplitude = amp
  .getInstance()
  .init(process.env.REACT_APP_AMPLITUDE);
export const USERS_REF = firestore.collection("users");
export const ACCOUNTS_REF = firestore.collection("accounts");
export const TRANSACIONS_REF = firestore.collection("transactions");

export const _USERS_REF = firestore.collection("_users");
export const _CUSTOMERS_REF = firestore.collection("_customers");
export const _TRANSACTIONS_REF = firestore.collection("_transactions");

export const PHONECONTACTS_REF = firestore.collection("phoneContacts");
export const ADS_REF = firestore.collection("ads");

export const GOOGLE_MAP_API = "AIzaSyCJovbvFyi1J7y04YO37TVfW6jsN1hQgUI";

export var themeColors = {
  whiteColor: "#fff",
  blackColor: "#000",
  appColor: "#267755",
  searchIconColor: "#737373",
  customerName: "rgba(0,0,0,0.85)",
  customerLastTransaction: "rgba(0,0,0,0.55)",
  amountDue: "#D73D51",
  amountPay: "#39B37F",
  amountClear: "#D2D2D2",
  accountSeparator: "rgba(0,0,0,0.10)",
  addAccountTooltip: "#434343",
  noCustomerIcon: "#cce5e4",
  touchRippleLight: "rgba(255,255,255,0.4)",
  touchRippleDark: "rgba(0,0,0,0.1)",
  searchContactsPlaceHolderText: "rgba(255,255,255,0.45)",
  searchContactsPlaceHolderTextOnFocus: "rgba(255,255,255,0.20)",
  imagePlaceholderBg: "#F8F8F8",
  addAccountFormTextFont: "rgba(0,0,0,0.85)",
  transactionListBg: "#fbfbfd",
  transactionBorder: "#E7E8EA",
  transactionTimeStampText: "rgba(0,0,0,0.45)",
  amountInputBorder: "#C1C2C9",
  calendarIcon: "#9B9BA1",
  datePickerText: "rgba(0,0,0,0.65)",
  datePickerDropdown: "#9B9BA1",
  redColor: "#e61b31",
  langText: "rgba(0,0,0,0.85)",
  langCheckIcon: "#34be94",
  countrySelectionPlaceholder: "rgba(0,0,0,0.85)",
  loginMobilePlaceholder: "rgba(0,0,0,0.25)",
  verificationText: "rgba(0,0,0,0.65)",
};

export var themeStyleSheet = {
  appColor: "#309592",
  // amountDue: "#34BE94",
  // amountPay: "#E96D6D",
  amountClear: "#d2d2d2",
  fontColor: "#414143",
  statusbarColor: "#0b195e",
  whitecolor: "#fff",
  orangeColor: "#4ac600",
  disableColor: "#ddd",
  disableColor2: "#969393",
  iconLightColor: "#d1d3d4",
  lightPurpleColor: "#615b81",
  headerbgColor: "#070d59",
  disableBtn: "#f4f4f4",
  disableColornew: "#dedede",
  greenColor: "#39b54a",
  greenColor: "#50b54f",
  redColor: "#e61b31",
  lightGrayColor: "#f0f0f0",
  borderLightColor: "rgba(0,0,0,0.1)",

  // FONT SIZE
  fontSize16: 16,
  fontSize12: 12,
  fontSize14: 14,
  fontSize16: 16,
  fontSize18: 18,
  fontSize20: 20,
  fontSize22: 22,
  fontSize24: 24,
  fontSize26: 26,
  fontSize28: 28,
  fontSize30: 30,
  fontSize32: 32,
  fontSize34: 34,
  fontSize36: 36,
  fontSize38: 38,
  fontSize40: 40,
  fontSize42: 42,
  fontSize44: 44,
  fontSize46: 46,
  fontSize66: 66,
  fontSize70: 70,
  fontSize90: 90,
  fontSize120: 120,
  fontSize150: 150,

  // Font Family
  robotoFont: "robotoRegular",
  robotoBoldFont: "robotoBold",
  robotoBoldFont: "robotoBold",
  robotoLightFont: "robotoLight",
  robotoThinFont: "robotoThin",
  robotoMediumFont: "robotoMedium",
  drawerWidth: 280,
};
export const SORT_OPTIONS = [
  "SORT_NAME",
  "SORT_AMOUNT",
  "SORT_LATEST",
  "SORT_PAYMENT_DUE",
];

export const ACOUNT_HEADER_OPTIONS = [
  "ACOUNT_CALL",
  "REPORTS",
  "ACCOUNT_PAYMENT_REMINDER_DATE_SET",
  "TRANSACTIONS_ALERT_SETTING",
];

export const LedgerType = {
  SHARED: "shared",
};

export const TransactionTypes = {
  CREDIT: "credit",
  DEBIT: "debit",
};

export const ACOUNT_HEADER_OPTIONS_ICONS = [
  "phone",
  "book-open-outline",
  "calendar-month",
];
