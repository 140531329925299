import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "30px",
  },

  title: {
    textAlign: "center",
    fontSize: 26,
  },
  heading: {
    textAlign: "center",
    fontSize: 14,
  },
  nextButton: {
    position: "fixed",
    height: "10vh",
    left: "0",
    bottom: "0",
    width: "100%",
  },
}));

export default useStyles;
