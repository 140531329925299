import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import en from './locales/en';
import ur from './locales/ur';
import si from './locales/si';
import pu from './locales/pu';
import pa from './locales/pa';
import roman_ur from './locales/roman-urdu';


// the translations
const resources = {
  en: {
    translation: en,
  },
  ur: {
    translation: ur,
  },
  si: {
    translation: si,
  },
  pu: {
    translation: pu,
  },
  pa: {
    translation: pa,
  },
  roman_ur: {
    translation: roman_ur,
  },
};

export const strings = (name, params = {}) => {
  return i18n.t(name);
}
export const changelocale = (lang, check) => {
  console.log("changelocale   ", lang, check)

  i18n.changeLanguage(lang);
}
i18n.use(reactI18nextModule).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  keySeparator: false,

  interpolation: {
    formatSeparator: ","
  },
});

export default i18n;
