import { Icon, Button, Box, IconButton } from "@material-ui/core";
import useStyles from "./styles";

const CreateTransactionButtons = ({ setShowModal }) => {
  const styles = useStyles();

  return (
    <div className={styles.chatContainer}>
      <Button
        className={styles.youGotButton}
        variant="contained"
        onClick={() => setShowModal(true)}
      >
        Message likhein...
      </Button>
      <Box className={styles.microphoneButton}>
        <IconButton
          aria-label="mic"
          color="inherit"
          onClick={() => setShowModal(true)}
        >
          <Icon>mic</Icon>
        </IconButton>
      </Box>
    </div>
  );
};

export default CreateTransactionButtons;
