import { makeStyles } from "@material-ui/core/styles"
import { themeStyleSheet, themeColors } from '../../../constants'

const useStyles = makeStyles((theme) => ({
    verificationCodeHeading: {
        fontSize: 22,
        textAlign: "center",
        color: themeColors.appColor,
        fontWeight: 700
    },
    otpInput: {
        width: "2.5rem !important",
        height: "2.5rem",
        margin: "20px 3px",
        fontSize: "1.5rem",
        borderRadius: "4px",
        border: "1px solid rgba(0,0,0,0.3)",
        color: "black"
    },
    verificationTextCont: {
        margin: "20px 0",
        textAlign: "center",
        maxWidth: "280px"
    }



}))

export default useStyles;