import { Box, Modal, Slide, Typography } from "@material-ui/core";
import HisabPointing from "../../../assets/HisabPointing.gif";
import PlayStoreLogo from "../../../assets/googleplay.png";
import CbLogo from "../../../assets/cb_logo_black.webp";
import useStyles from "./styles";

const DownloadCbModal = ({ setShowModal, clickDownloadCbApp }) => {
  const styles = useStyles();

  return (
    <Modal
      open={true}
      className={styles.modalWrapper}
      onClick={() => setShowModal(false)}
    >
      <Slide container direction="up" in="checked" timeout={1000}>
        <Box className={styles.modalContainer}>
          <Box className={styles.paddingBox}>
            <img
              src={HisabPointing}
              alt="Hisab-Pointing"
              className={styles.hisabPointing}
            />
            <div className={styles.playStoreLogoContainer}>
              <img
                src={PlayStoreLogo}
                alt="PlayStore-Logo"
                onClick={clickDownloadCbApp}
              />
            </div>
            <Typography className={styles.normalFont}>
              <b>CreditBook</b> sey Khatay mein entries ke sath chat bhi karein!
            </Typography>
            <div className={styles.creditBookLogoContainer}>
              <img src={CbLogo} alt="Cb-Logo" width={140} />
            </div>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default DownloadCbModal;
