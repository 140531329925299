import { Icon, Button } from "@material-ui/core";
import useStyles from "./styles";

const CreateTransactionButtons = ({ setShowModal }) => {
  const styles = useStyles();

  return (
    <div className={styles.createTransactionContainer}>
      <Button
        className={styles.youGotButton}
        variant="contained"
        startIcon={<Icon>add</Icon>}
        onClick={() => setShowModal(true)}
      >
        Aapko Miley
      </Button>
      <Button
        className={styles.youGaveButton}
        variant="contained"
        startIcon={<Icon>remove</Icon>}
        onClick={() => setShowModal(true)}
      >
        Aapne Diye
      </Button>
    </div>
  );
};

export default CreateTransactionButtons;
