import { AnalyticsBrowser } from "@segment/analytics-next";

export const seed = (num) => {
  let x = Math.sin(num) * 10000;
  return x - Math.floor(x);
};

export const NetInfo = async () => {
  var condition = navigator.onLine ? "online" : "offline";
  if (condition === "online") {
    console.log("ONLINE");
    fetch("https://www.google.com/", {
      // Check for internet connectivity
      mode: "no-cors",
    })
      .then(() => {
        console.log("CONNECTED TO INTERNET");
      })
      .catch(() => {
        console.log("INTERNET CONNECTIVITY ISSUE");
      });
  } else {
    console.log("OFFLINE");
  }
};
export const formatNum = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const formattedDate = (timestamp) => {
  let dateObj = new Date(
    timestamp.seconds ? timestamp.seconds * 1000 : timestamp
  );
  var month = dateObj.getMonth() + 1; //months from 1-12

  var day = dateObj.getDate();

  var year = dateObj.getFullYear();

  return (
    (day.toString().length === 1 ? `0${day}` : day) +
    "-" +
    (month.toString().length === 1 ? `0${month}` : month) +
    "-" +
    year
  );
};

export const formatPhoneNumber = (val = "") => {
  let num = val.replace("+92", "0");
  return num;
};

const urlMessage = window.location.pathname.substring(1);
const paramsStr = urlMessage.split("&");
const isStaging = paramsStr[1] === "staging"; //since params are always retrieved as string
const writeKey = isStaging ? process.env.REACT_APP_SEGMENT_WRITE_KEY_STAGE : process.env.REACT_APP_SEGMENT_WRITE_KEY_PROD;
export const SegmentAnalytics = AnalyticsBrowser.load({ writeKey: writeKey})
