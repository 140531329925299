export default {
    LOGIN_VERIFICATION_CODE_TEXT: "يو تصديقي کوډ په دې لېږل شوے دے",
    LOGIN_COUNTRY_TEXT: "ملک منتخب کړئ",
    LOGIN_PHONENUMBER: "فون نمبر",
    WELCOME: "ستړي مه شئ",
    LOGIN: "لاګ ان",
    CHANGE_LANGUAGE: "ژبه بدله کړئ",
    SELECT_LANGUAGE: "ژبه منتخب کړئ",
    ADD_ACCOUNT: "نوے اکاونټ شامل کړئ",
    PAY: "ادا",
    DUE: "بقايا",
    CLEAR: "وران کړئ",
    PAID: "پېسې جمع شوې",
    ADDED_ON: "پرې داخل شئ",
    TOOK_LOAN: "قرضه مو واخسته",
    RUPEES: "روپۍ",
    SEARCH_CONTACTS: "اکاونټ ولټوئ",
    SALAM: "سلام",
    NEW_CONTACT: "نوے اکاونټ",
    GIVE_PAYMENT: "ادائيګي وکړئ",
    RECEIVE_PAYMENT: "پېسې ترلاسه کړئ",
    CHOOSE_YOUR_LANGUAGE: "خپله ژبه منتخب کړئ",
    NEXT: "مخکښې",
    BACK: "وروستو",
    VERIFICATION_CODE: "تصديقي کوډ",
    A_verification_code_will_be_sent_on_your_number_via_SMS_Service_charges_may_apply: "يو تصديقي کوډ به ستاسو په رجسټر شوي نمبر لېږل کېږي",
    DIDNT_GET_CODE: "ايا تر اوسه تاسو ته کوډ نه دے ترلاسه شوے؟",
    RESEND_CODE: "کلوډ بيا ولېږئ",
    ENTER_MOBILE_NUMBER: "خپل موبائل نمبر وليکئ",
    PAKISTAN_92: "پاکستان (+۹۲)",
    PERSONAL_INFORMATION: "ذاتي معلومات",
    GET_STARTED: "پېل کړئ",
    LOGOUT: "بهر ووځئ",
    HOME: "کورپاڼه",
    ADD: "جمع کړئ",
    NO_ACCOUNT_FOUND_NAMED: "د دې نوم هېڅ اکاونټ نشته",
    CONTACTS_FROM_PHONEBOOK: "د فون بک اړيکې",
    SETTINGS: "تنظيمول",
    ABOUT_US: "زمونږ په اړه",
    PRIVACY_POLICY: "د رازدارۍ پاليسي",
    HELP: "مرسته",
    CONTACT: "اړيکه ونيسئ",
    SORT_NAME: "نوم",
    SORT_AMOUNT: "رقم يا پېسې",
    SORT_LATEST: "تازه ترين",
    SORT_PAYMENT_DUE: "پاتې پېسې",
    NAME: "نوم",
    PHONE_NUMBER: "د ټيليفون شمېره",
    BUSINESS_NAME: "د کاروبار نوم",
    TODAY: "نن",
    YESTERDAY: "پرون",
    ENTER_AMOUNT: "پېسې وليکئ",
    WRITE_NOTE_HERE: "نور معلومات وليکئ",
    FULL_NAME: "پوره نوم",
    EMAIL_ADDRESS: "د اي مېل پته",
    BUSINESS_TYPE: "د کاروبار قسم",
    TRANSACTION_INFO: "د لېن دېن معلومات",
    SYNC_SUCESSFULLY: "په کاميابۍ سره وشو",
    NOT_SYNC: "په کاميابۍ سره ونه شو",
    SHARE_TRANSACTION: "لېن دېن شريک کړئ",
    DELETE_TRANSACTION: "لېن دېن ختم کړئ",
    NOTE_NOT_SPECIFIED: "معلومات واضح نه دي",
    ADVANCE: "له وړاندې اخستل",
    ACCOUNT: "اکاونټ",
    WHATSAPP: "واټس اپ",
    SHARE: "د خپلو اړيکو سره شريک کړئ",
    VERSION: "نسخه",
    UPDATE: "تازه",
    UPDATE_ACCOUNT: "اکاونټ تازه کړئ",
    ACCOUNTS: "اکاونټس",
    USER_STATEMENT: "د صارف بيان",
    PROFILE: "پروفائل/پېژندنه",
    LANGUAGE: "ژبه",
    BALANCE: "بقايه پېسې",
    NOTE: "نوټ",
    DELETE_ACCOUNT: "اکاونټ ختم کړئ",
    NET_BALANCE: "ټول رقم/ټولې پېسې",
    CREDITS: "ورکړل شوې پېسې",
    PAYMENTS: "ادا شوې پېسې",
    DO_YOU_WANT_TO_DELETE_THIS_TRANSACTION: "ايا تاسو دا لېن دېن ختمول غواړئ؟",
    TRANSACTION_HAS_BEEN_DELETED: "ستاسو دا لېن دېن ختم کړے شو",
    RESTORE_TRANSACTION: "لېن دېن واپس کړئ",
    DO_YOU_WANT_TO_RESTORE_THIS_TRANSACTION: "ايا تاسو دا لېن دېن واپس راوستل غواړئ؟",
    TRANSACTION_HAS_BEEN_RESTORED: "ستاسو لېن دېن په کاميابۍ سره بېرته راوستل شو",
    TRANSACTION_MESSAGE_WHEN_GIVING: "مبارک شئ تاسو۔۔۔۔۔۔۔۔۔۔۔ روپۍ ورکړې",
    TRANSACTION_MESSAGE_WHEN_TAKING: "مبارک شئ تاسو۔۔۔۔۔۔۔۔۔۔۔۔۔۔۔۔۔۔ روپۍ واخستې",
    VERIFIED_BY_CREDIT_BOOK: "د کرېډټ بک نه تصديق شوې",
    AMOUNT_GIVEN: "ورکړل شوے رقم",
    AMOUNT_RECEIVED: "وصول شوے رقم",
    PLEASE_ENTER_AMOUNT: "مهرباني وکړئ رقم وليکئ",
    PLEASE_ENTER_MOBILE_NUMBER: "مهرباني وکړئ خپل د موبائل شمېره وليکئ",
    PLEASE_CHECK_INTERNET_CONNECTION: "مهرباني وکړئ خپل د انټرنټ کنکشن وګورئ",
    SOMETHING_WENT_WRONG: "اوو۔۔ څه خرابتيا مخې ته راغله",
    CODE_RESENT: "کوډ بيا ولېږل شو",
    UPDATE_NOTE: "معلومات تازه کړئ",
    ADD_CUSTOMER: "صارف درج کړئ",
    SEARCH_CUSTOMERS: "صارف ولټوئ",
    NEW_CUSTOMER: "نوے صارف",
    NO_CUSTOMER_FOUND_NAMED: "هېڅ يو صارف ونه موندل شو",
    UPDATE_CUSTOMER: "صارف تازه کړئ",
    DELETE_CUSTOMER: "صارف ختم کړئ",
    CUSTOMERS: "صارفين",
    SEND_REMINDER: "يادونه ولېږئ",
    SHARE_STATEMENT: "بيان شريک کړئ",
    CREDIT_LIMIT: "د قرض حد",
    CONTACT_US: "مونږ سره اړيکه ونيسئ",
    ADD_FROM_PHONEBOOK: "د خپلو اړيکو نه درج کړئ",
    SEARCH_FROM_PHONEBOOK: "په خپلو اړيکو کښې ولټوئ",
    SORT_TYPE: "تازه ترين",
    NO_TRANSACTIONS_AVAILABLE: "هېڅ لېن دېن موجود نه دے",
    SHARE_MSG: "هم اوس کرېډټ بک ډاونلوډ کړئ او خپل حساب کتاب په موثره طريقه سمبال کړئ",
    CONTACT_US_MESSAGE: "سلام! زه غواړم د کرېډټ بک په استعمال کښې زما مرسته وکړئ",
    CHANGE_LANGUAGE_CONFIRMATION: "د دې خصوصيت د استعمال لپاره تاسو اېپ بند کړۍ، بيا چالو کړئ، ايا تاسو مخکښې تلل غواړئ؟",
    USER_CONSENT_MESSAGE: "مخکښې تلو سره تاسو زمونږ شرائط، حالات او د رازدارۍ پاليسي منئ",
    START_DATE: "د شروع نېټه",
    END_DATE: "د اختتام نېټه",
    THIS_MONTH: "دا مياشت",
    CUSTOM_RANGE: "د خپلې خوښې مطابق",
    CREDIT_LIMIT_EXCEED_MSG: "ستاسو د قرضې د حد نه زيات شو، ايا تاسو مخکښې تلل غواړئ",
    WARNING: "خبردارے",
    INVALID_MOBILE_NO_ERROR: "ستاسو د موبائل نمبر غلط دے۔ سم شکل ئې دا دے 03451234567",
    FIELD_REQUIRED: "دا ځايونه ډک کړئ",
    CREDIT_LIMTI_CANT_BE_ZERO: "د قرض حد صفر نه شي کېدلے، که چرې تاسو حد ټاکل نه غواړئ نو خالي ئې پرېږدئ",
    DO_YOU_WANT_TO_DELETE_THIS_CUSTOMER: "ايا تاسو دا صارف لرې کول غواړئ؟",
    ENTER_YOUR_NOTE_HERE: "خپل نوټ دلته وليکئ",
    START_DATE: "د شروع نېټه",
    END_DATE: "د اختتام نېټه",
    THIS_MONTH: "دا مياشت",
    CUSTOM_RANGE: "د خپلې خوښې مطابق",
    GIVE_PAYMENT: "تاسو۔۔۔۔۔ روپۍ ورکړې",
    RECEIVE_PAYMENT: "تاسو ته ۔۔۔۔۔۔۔۔۔۔ روپۍ ترلاسه شوې",
    REPORTS: "رپورټونه",
    CANCEL: "کېنسل",
    REMINDER_HAS_BEEN_SENT: "يادونه د پېغام په ذريعه ولېږل شوه",
    MOBILE_NO_NOT_ADDED: "موبائل شمېره نه ده درج شوې",
    MOBILE_NO_NOT_ADDED_DESCRIPTION: "د اکاونټ نوم لپاره د موبائل شمېره نه ده درج شوې ايا تاسو د موبائل شمېره درج کول غواړئ؟",
    SOMETHING_WENT_WRONG_WHILE_SENDING_SMS: "د پېغام لېږلو په مهال څه غلطي وشوه",
    ADD_YOUR_FIRST_CUSTOMER: "خپل اولنے صارف درج کړئ",
    PRESS_THE_BUTTON_FOR_ADDING_YOUR_FIRST_CUSTOMER: "دا بټن ووهئ چې اولنے صارف درج شي",
    GO_TO_DASHBOARD: "ډېش بورډ ته لاړ شئ",
    MESSAGE_CONFIRMATION_BEFORE_SENDING: "يادونه مو وکړه",
    MOBILE_NUMBER_INVALID_MESSAGE: "د موبائل نمبر موجود نه دے",
    CONTACT_NOT_EXESTING_MESSAGE: "د (اکاونټ نوم) موبائل نمبر موجود نه دے۔ ايا تاسو د موبائل نمبر درج کول غواړئ؟",
    SMS_NOT_GOING_THROUGH: "د پېغام لېږلو په مهال څه خرابي راغلې، مهرباني وکړئ بيا کوشش وکړئ",
    TOOLTIP_TEXT_ONBOARDING_WHEN_ADDED_FIRST_CUSTOMER: "تاسو خپل اولنے صارف درج کړو۔ د پېسو تر لاسه کولو يا ورکولو لپاره صارف باندې کلک وکړئ",
    TOOLTIP_TEXT_ONBOARDING_WHEN_ADDED_FIRST_TRANSACTION: 'تاسو خپل لېن دېن درج کړو۔ په کرېډټ بک کښې نور خصوصيات هم موجود دي۔ د نورو معلوماتو لپاره د "؟" نخښه باندې کلک وکړئ او د مرستې ويډيوګانې وګورئ',
    GIVE_PAYMENT: "تاسو ورکړې",
    RECEIVE_PAYMENT: "تاسو واخستې",
    REMINDERS: "يادګېرنه",
    PAYMENT_DUE_DATE: "د ادائيګۍ اخري نېټه",
    ACOUNT_CALL: "کال",
    ACCOUNT_PAYMENT_REMINDER_DATE_SET: "ادائیگی دی یادہانی دی تاریخ درج کرو",
    AUTO_REMINDER: "پخپله يادګېرنه",
    REMINDER_DATE: "د يادګېرنې نېټه",
    FREE_SMS: "ويړيا پېغام",
    REMIND_ALL: "ټولو ته ور ياد کړئ",
    REMIND: "ورياد کړئ",
    REMINDER_SENT: "يادګېرنه ولېږلے شوه",
    SMS_ABOUT_TO_SEND_ALL: "تاسو پېغام لېږونکي يئ, هغه ټولوصارفينو ته کومو ته چې بقايا رقم ورکول دي! تاسو جاري ساتل غواړئ؟ نوټ: پېغام به صرف هاغه صارفينو ته ځي د کومو چې نمبر درج دے",
    APPLY_FILTERS: "فلټر وچلوئ",
    LOWEST_AMOUNT: "کم نه کم رقم",
    HIGHEST_AMOUNT: "زيات نه زيات رقم",
    SORT_BY: "د ترتيب ذريعه",
    GREATER_THAN: "نه زيات",
    EQUAL_TO: "د برابر",
    LESS_THEN: "نه کم",
    AMOUNT: "رقم",
    ADD_FILTERS: "فلټر وچلوئ",
    ENTER_VALUE: "ماليت درج کړئ",
    TRANSACTIONS_ALERT_SETTING: "د لېن دېن خبردارے ۔۔ ترتيبات",
    TRANSACTION_ALERTS: "د لېن دېن خبردارے",
    TRANSACTION_TOGGLE_MESSAGE: "هر کله چې هم څه لېن دېن کېږي، خبردارے ئې د پېغام په ذريعه صارف ته ځي",
    DATA_SECURITY_MESSAGE: "ستاسو معلومات او لين دين په مکمله توګه محفوظ دي.",
    AUTO_REMINDER_MESSAGES: "هره ورځ سحر شپږ بجي یادګېرنه په خپله لېږل کېږي",
    SCHEDULED_FOR_LATER: "د بل وخت لپاره مقرر کړئ",
    TO_BE_SENT: "لېږل دي",
    DEBITS: "ډبټ",
    APPLOCK: "اېپ لاک",
    CREATE_A_NEW_PIN: "نوے پن جوړ کړئ",
    CONFIRM_PIN: "د تصديق پن",
    PIN_NOT_MATCHED_ERROR: "پن سم نه دے، مهرباني وکړئ بيا وليکئ",
    APPLOCK_ENABLED: "اېپ لاک ولګول شو",
    APP_LOCK_UPDATED: "اېپ لاک تازه شو",
    REMOVE_APPLOCK: "اېپ لاک لرې کړئ",
    CHANGE_PIN: "پن بدل کړئ",
    RE_ENTER_YOUR_PIN: "پن بيا وليکئ",
    REMOVE_PIN: "پن لرې کړئ",
    INVALID_PIN_ERROR: "غلط پن۔ مهرباني وکړئ بيا وليکئ",
    APP_LOCK_REMOVED: "اېپ لاک لرې کړل شو",
    ENTER_YOUR_OLD_PIN: "پخوانے پن وليکئ",
    ENTER_YOUR_PIN: "خپل پن وليکئ",
    CONTACT_SUPPORT: "د مرستې لپاره رابطه وکړئ",
    FORGOT_YOUR_PIN: "ايا پن مو هېر دے؟",
    CLICK_HERE: "دلته کلک وکړئ",
    DATA_BACKUP: "ټول معلومات محفوظول",
    YOUR_DATA_HAS_BEEN_BACKED_UP: "ايا ستاسو ټول معلومات محفوظ شو؟",
    DATA_BACKUP_DESC: "مهرباني وکړئ انټرنټ ولګوئ چې ستاسو معلومات محفوظ شي",
    LAST_BACKUP_AT: "اخري ځل ستاسو معلومات محفوظ شوي",
    SMS_ALERT_LANGUAGE: "د پېغام د خبرتيا ژبه",
    TRANSACTIONAL_SMS_SAMPLE: "د لېن دېن د پېغام نمونه",
    CONFIRM_LANGUAGE: "ژبه تائيد کړئ",
    TOTAL: "ټول",
    CUSTOMER_STATEMENT: "د صارف تفصيلات ",
    ALL: "ټول",
    BUSINESS_CARD: "د کاروبار کارډ",
    SAMPLE_CARD_DESIGN: "د کارډ نمونه",
    CREATE_A_NEW_BUSINESS_CARD: "نوے د کاروبار کارډ جوړ کړئ",
    ALTERNATE_PHONE_NUMBER: "دوېم ټېليفون نمبر",
    LOCATION: "ځاے",
    AUTOMATICALLY_PICKS_UP_CURRENT_LOCATION_SHOWN_IN_GOOGLE_MAP_BELOW: "په ګوګل مېپ يا نقشه کښې ورکړل شوو ځايونو نه خپل ځاے منتخب کړئ",
    SAVE_CARD: "سیو کارڈ",
    SHARE: "د خپلو اړيکو سره شريک کړئ",
    DELETE: "لرې کړئ",
    EDIT: "ترميم کړئ",
    CREATE_NEW_CARD: "نوے د کاروبار کارډ جوړ کړئ",
    COLLECT_PAYMENTS_3_TIMES_FASTER: "پېسې درې چنده په تېزۍ سره ترلاسه کړئ",
    SET_COLLECTION_DATES: "د پېسو اخستلو نېټه منتخب کړئ",
    REMINDERS_GO_OUT_1_DAY_BEFORE: "ياددهاني يوه ورځ مخکښې لېږل کېږي",
    COLLECT_PAYMENTS_3_X_FASTER: "ادائيګي درې چنده تېزه ترلاسه کړئ",
    ADDRESS: "پته",
    BUSINESS_CARD_HAS_BEEN_CREATED: "بزنس کارډ جوړ‎ شو",
    BUSINESS_CARD_HAS_BEEN_UPDATED: "بزنس کارډ نوے شو",
    DATE_RANGE: "د معلوماتو کچه",
    INVALID_AMOUNT: "غلط رقم",
    COLLECTION_DATE: "د جمع کولو نېټه",
    YOUR_DATA_IS_100_SAFE_AND_SECURE: "ستاسو معلومات ۱۰۰ فيصده محفوظ دي",
    TRANSACTION_HAS_BEEN_UPDATED: "لېن دېن نوے شو",
    MORE: "نور",
    PROMOTE: "وده ورکړۍ",
    ADVERTISE_YOUR_PRODUCTS: "خپل محصولات مشتهر کړئ",
    ADVERTISE_YOUR_PRODUCTS_DESC: "د کاروباري ودې د خاصې موقعې په اړه د واټس اپ په ذريعه خپلو صارفيونو ته ووايئ",
    CREATE_A_NEW_AD: "نوے اشتهار جوړ کړئ",
    STEP_1: "لومړے پړاو",
    TAKE_OR_UPLOAD_A_PICTURE_OF_THE_PRODUCT: "د هغه څيز تصوير وباسئ چې تاسو ئې مشتهر کول غواړئ۔ دلته اپلوړ کړئ او لږ تفصيل وليکئ",
    TAK_UPLOAD_A_PICTURE: "تصوير واخلئ/تصوير اپلوډ کړئ",
    CREATE_A_NEW_AD_POST_TO_SHARE: "د خپرولو لپاره نوے اشتهار جوړ کړئ",
    THIS_IS_WHATSAPP_TEXT_MESSAGE: "دا د واټس اپ پېغام دے",
    STEP_2: "دوېم پړاو",
    DO_YOU_WANT_TO_SEND_AN_ACCOMPANYING_MESSAGE: "ايا تاسو د دې سره يو بل پېغام لېږل غواړئ؟",
    PENDING: "زېر غور",
    UPCOMING: "راروان",
    COLLECTION_DATE_NOT_SET: "د جمع کولو نېټه مقرره نه ده",
    SELECT_DATE: "نېټه وټاکئ",
    SEND_REMINDER: "ياددهاني ولېږئ",
    SELECT_ON_MAP: "نقشه منتخب کړئ",
    LOCATION_UPDATED: "ځاے مو تازه شو",
    WELCOME_TO_CREDITBOOK: "کرېډټ بک ته پخېر راغلئ",
    MANAGE_FINANCES_TRACK_PAYMENTS: "ماليه سمبال کړئ، ادائيګي وګورئ، ترلاسه کېدونکي نوي کړئ۔ اوس قرض هم ډيجيټل شو",
    LETS_GO: "ځئ",
    KHATA_AB_PHONE_PE: "کهاته اوس په ټيليفون باندې",
    STAY_INFORMED_AND_BE_IN_CONTROL_OF_CREDIT: "خبر اوسئ او خپل قرض د ګاهګ او عرضه ورکوونکي سره د لاس لاندې وساتئ",
    YOUR_DATA_IS_SAFE_AND_SECURED: "ستاسو معلومات محفوظ دي",
    TEXT_ABOUT_CLOUD_SECURITY_GOES_HERE: "د کلاؤډ سېکيورټي په اړه پېغام دلته او دلته راځي",
    ASAAN_HISAAB_KITAAB_ACCOUNTS: "اسان حساب کتاب په منټونو کښې۔ د ډېرو کاغذوونو نه تېرېدل او د صارفينو سره بحث کول نور نه۔",
    SKIP: "پرېږدئ",
    TITLE: "سرليک",
    ADD_YOUR_CAPTION_HERE: "خپل کېپشن/معلومات دلته وليکئ",
    DONE: "وشو",
    AD_INSERTED_SUCCESS: "اشتهار ولګېدو",
    ONLINE_NOTE: "تاسو ان لائن اېپ استعمالوئ",
    OFFLINE_NOTE: "تاسو اف لائن اېپ استعمالوئ",
    TITLE_IS_REQUIRED: "سرليک ضروري دے",
    AD_UPDATED_SUCCESS: "اشتهار تازه شو",
    CAROUSEL_DESC: "هره ورځ خپل کاروباري اکاونټ تازه کړئ او وخت بچ کړئ۔ د ګاهګ سره بحث او په کاغذونو کښې وخت ضائع کول اوس ختم دي",
    EXISTING_CUSTOMER: "زوړ ګاهګ",
    ADD_A_STARTING_BALANCE: "وړومبے بېلنس واچوئ",
    STARTING_BALANCE: "وړومبے بېلنس",
}