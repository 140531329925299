import UserAction from "../actions/User";
import { firebase, auth } from "../../constants";
import {
    USERS_REF,
    _USERS_REF,
    ACCOUNTS_REF,
    TRANSACIONS_REF,
} from "../../constants";
import {
    loginWithCustomToken,
    resendVerificationCode,
    getTokenFromUUID,
} from "../api/User";

let unsubscribe = null;

export default class UserMiddleware {
    static login(user) {
        return (dispatch) => {
            console.log("In login user action");
            dispatch(UserAction.loginUser(user));
        };
    }

    static loginWithCustomToken(mobile_no, signature) {
        console.log("in user middleware", mobile_no, signature)
        return new Promise((resolve, reject) => {
            loginWithCustomToken(mobile_no, signature)
                .then(function (response) {
                    console.log("response.data", response.data);
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.log(error, "error");
                    reject();
                });
        });
    }

    static resendVerificationCode(mobile_no) {
        return new Promise((resolve, reject) => {
            resendVerificationCode(mobile_no)
                .then(function (response) {
                    console.log("response.data", response.data);
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.log(error, "error");
                    reject();
                });
        });
    }

    static verifyLogin(uid) {
        return new Promise((resolve, reject) => {
            getTokenFromUUID(uid)
                .then(function (response) {
                    console.log("response.data", response.data);

                    resolve(response.data.customToken);
                })
                .catch(function (error) {
                    console.log(error, "error");
                    reject();
                });
        });
    }

    static getUser(userId) {
        return (dispatch, getState) => {
            return new Promise((resolve, reject) => {
                console.log("getting user from firestore !!!");
                if (unsubscribe) {
                    try {
                        unsubscribe();
                    } catch (err) {
                        console.log("err : ", err);
                    }
                }
                let query = _USERS_REF.where("id", "==", userId);
                unsubscribe = query.onSnapshot(
                    { includeMetadataChanges: true },
                    function (querySnapshot) {
                        if (!querySnapshot.metadata.fromCache) {
                            console.log("querySnapshot : ", querySnapshot);
                            console.log("querySnapshot.docs : ", querySnapshot.docs);
                            console.log("##### ", querySnapshot.docs[0].data());
                            let user = querySnapshot.docs[0].data();
                            user = { ...user, isAuthenticated: true }
                            dispatch(UserAction.loginUser(user));
                            resolve();
                        } else {
                            resolve();
                        }
                    }
                );
            });
        };
    }
    static logout() {
        return (dispatch) => {
            return new Promise((resolve, reject) => {
                console.log("In logout user action");
                auth
                    .signOut()
                    .then((res) => {
                        dispatch(UserAction.logoutUser());
                        console.log("Logout from firebase successfully !!!!");
                        resolve(res);
                    })
                    .catch((err) => {
                        console.log("Logout from firebase error : ", err);
                        reject(err);
                    });
            });
        };
    }
}
