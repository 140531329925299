import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Modal, Box } from "@material-ui/core";
import axios from "axios";
import fileDownload from "js-file-download";

import ReciptBorderTop from "../../../assets/receipt-border-top.png";
import ReciptBorderBottom from "../../../assets/receipt-border-bottom.png";
import CbLogo from "../../../assets/cb_logo.webp";
import DownloadLogo from "../../../assets/download.png";
import WhatsappLogo from "../../../assets/whatsapp.png";
import CrossLogo from "../../../assets/Cross.webp";
import { formattedDate } from "../../../constants";
import Loader from "../Loader";
interface invoiceDetailsProps {
  showModal: boolean;
  closeModal: any;
  invoice?: any;
  ownerMobileNumber: string;
  isStagingEnv: boolean;
}

export const CustomerInvoiceModal: FC<invoiceDetailsProps> = ({
  showModal,
  closeModal,
  isStagingEnv,
  invoice,
  ownerMobileNumber,
}) => {
  const styles: any = useStyles();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiUrl = isStagingEnv
    ? process.env.REACT_APP_API_URL_STAGE
    : process.env.REACT_APP_API_URL_PROD;

  useEffect(() => {
    setItems(invoice?.items);
  }, [invoice]);

  const downloadReport = () => {
    setLoading(true);

    let URL = `${apiUrl}/api/v1/report/internal/invoice`;
    axios
      .get(URL, {
        params: {
          invoice_id: invoice?.firestore_id,
          mobile_number: ownerMobileNumber,
          business_name: invoice?.businessName,
          customer_id: invoice?.customer_id || "",
        },
      })
      .then(async (res) => {
        const response = await fetch(res?.data);
        const buffer = await response.arrayBuffer();

        const fileName = res?.data?.split("/")?.[3];

        fileDownload(buffer, fileName);
        setLoading(false);
      })
      .catch((err) => {
        console.error("catch error", err);
        setLoading(false);
      });
  };

  const share = async () => {
    setLoading(true);
    let URL = `${apiUrl}/api/v1/report/internal/invoice`;

    axios
      .get(URL, {
        params: {
          invoice_id: invoice?.firestore_id,
          mobile_number: ownerMobileNumber,
          business_name: invoice?.businessName,
          customer_id: invoice?.customer_id || "",
        },
      })
      .then(async (res) => {
        const response = await fetch(res?.data);
        const buffer = await response.arrayBuffer();

        const fileName = res?.data?.split("/")?.[3];

        const pdf = new File([buffer], fileName, {
          type: "application/pdf",
        });
        const files = [pdf];

        // Share PDF file if supported.
        if (navigator.canShare({ files }))
          await navigator.share({
            files,
            title: "Invoice Report",
            text: fileName,
          });
        setLoading(false);
      })
      .catch((err) => {
        console.error("catch error", err);
        setLoading(false);
      });
  };

  return (
    <Modal
      open={showModal}
      onClose={() => closeModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ backdropFilter: "blur(2px)" }}
    >
      <>
        <Loader loading={loading} />
        <Box className={styles.modalContainer}>
          <Box
            className={styles.modalCrossBtn}
            onClick={() => closeModal(false)}
          >
            <img className={styles.crossTop} src={CrossLogo} alt="ReceiptTop" />
          </Box>
          <img
            className={styles.receiptTop}
            src={ReciptBorderTop}
            alt="ReceiptTop"
          />
          <Box className={styles.modalInnerContainer}>
            <div className={styles.invoiceHeaderContainer}>
              <Typography className={styles.headerTitle}>
                {invoice?.businessName}
              </Typography>
              <Typography className={styles.headerSubtitle}>
                {formattedDate(invoice?.created_at)}
              </Typography>
            </div>
            <div className={styles.invoiceHeaderContainer}>
              <Typography className={styles.headerTitle}>
                {invoice?.customer_name}
              </Typography>
              <Typography className={styles.headerSubtitle}>
                {`Bill #${invoice?.bill_number}`}
              </Typography>
            </div>
            <div className={styles.headerBottomBorder}></div>
            <div className={styles.scrollableDiv}>
              {!invoice?.image ? (
                <div className={styles.itemHeader}>
                  <div className={styles.textContainerLeft}>
                    <Typography className={styles.headerTitle}>
                      Tafseel
                    </Typography>
                  </div>
                  <div className={styles.textContainerRight}>
                    <Typography className={styles.headerTitle}>Qty</Typography>
                  </div>
                  <div className={styles.textContainerRight}>
                    <Typography className={styles.headerTitle}>Rate</Typography>
                  </div>
                  <div className={styles.textContainerRight}>
                    <Typography className={styles.headerTitle}>Rs.</Typography>
                  </div>
                </div>
              ) : (
                <div className={styles.billImageContainer}>
                  <img
                    src={invoice?.image}
                    className={styles.billImage}
                    alt="bill-img"
                  />
                </div>
              )}

              {!invoice?.image &&
                items?.map((item: any, index: number) => {
                  return (
                    <div className={styles.itemContainer} key={index}>
                      <div className={styles.textContainerLeft}>
                        <Typography className={styles.headerTitle}>
                          {item?.name || "Item"}
                        </Typography>
                      </div>
                      <div className={styles.textContainerRight}>
                        <Typography className={styles.headerTitle}>
                          {item?.quantity}
                        </Typography>
                      </div>
                      <div className={styles.textContainerRight}>
                        <Typography className={styles.headerTitle}>
                          {item?.rate}
                        </Typography>
                      </div>
                      <div className={styles.textContainerRight}>
                        <Typography className={styles.headerTitle}>
                          {item?.rate * item?.quantity}
                        </Typography>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className={styles.headerBottomBorder}></div>
            <div className={styles.logoTotalContainer}>
              <div className={styles.cbLogo}>
                <img
                  width={"24px"}
                  height={"24px"}
                  src={CbLogo}
                  alt="CB-Logo"
                />
              </div>
              <div className={styles.totalContainer}>
                <Typography className={styles.total}>Total</Typography>
                <Typography
                  className={styles.totalValue}
                >{`Rs. ${invoice?.total_bill}`}</Typography>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <div
                className={styles.downloadButtonContainer}
                onClick={() => downloadReport()}
              >
                <img width={12} src={DownloadLogo} alt="Download" />
                <Typography className={styles.downloadButtonText}>
                  Download
                </Typography>
              </div>
              <div
                className={styles.okayButtonContainer}
                onClick={() => share()}
              >
                <img width={12} src={WhatsappLogo} alt="share" />
                <Typography className={styles.okayButtonText}>Share</Typography>
              </div>
            </div>
          </Box>
          <img
            className={styles.receiptBottom}
            src={ReciptBorderBottom}
            alt="ReceiptBottom"
          />
        </Box>
      </>
    </Modal>
  );
};

const useStyles = makeStyles(() => ({
  modalContainer: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    transform: "translate(-50%, -50%)",
    width: "335px",
    borderRadius: "8px",
    p: 4,
  },
  receiptTop: {
    marginTop: "0rem",
  },
  crossTop: {
    width: "15px",
    height: "15px",
  },
  receiptBottom: {
    marginBottom: "-2rem",
  },
  modalInnerContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "15px",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
  },
  headerTitle: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#626262",
  },
  headerSubtitle: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#626262",
  },
  invoiceHeaderContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerBottomBorder: {
    width: "100%",
    marginTop: "0.5rem",
    borderRadius: "4px",
    border: "1px solid #DBDBDB",
  },
  scrollableDiv: {
    overflowY: "scroll",
    flexDirection: "column",
    height: "250px",
  },
  itemHeader: {
    width: "100%",
    marginTop: "8px",
    marginBottom: "16px",
    flexDirection: "row",
    justifyContent: "space-between",
    display: "flex",
  },
  itemContainer: {
    width: "100%",
    marginTop: "8px",
    // height: "250px",
    // flexDirection: "row",
    justifyContent: "space-between",
    display: "flex",
  },
  textContainerLeft: {
    textAlign: "left",
    flex: 1,
  },
  textContainerRight: {
    textAlign: "right",
    flex: 0.5,
  },
  logoTotalContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    marginTop: "27px",
    justifyContent: "center",
  },
  cbLogo: {
    display: "flex",
    flex: 0.4,
    justifyContent: "start",
    alignItems: "center",
  },
  totalContainer: {
    display: "flex",
    flex: 1,
    height: "40px",
    backgroundColor: "#F5F5F5",
    borderRadius: "4px",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  total: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#626262",
  },
  totalValue: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C1C1C",
  },
  buttonContainer: {
    display: "flex",
    flex: 1,
    marginTop: "1rem",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  okayButtonContainer: {
    display: "flex",
    width: "40%",
    padding: "10px",
    backgroundColor: "#267755",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center",
  },
  okayButtonText: {
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "12px",
    marginLeft: "5px",
  },
  downloadButtonText: {
    color: "#267755",
    fontWeight: 500,
    fontSize: "12px",
    marginLeft: "5px",
  },
  downloadButtonContainer: {
    display: "flex",
    width: "40%",
    padding: "10px",
    backgroundColor: "#E9F1EE",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center",
  },
  billImageContainer: {
    paddingTop: "30px",
    justifyContent: "center",
    alignItems: "center",
  },
  billImage: {
    height: "200px",
    width: "100%",
  },
  modalCrossBtn: {
    width: "32px",
    height: "32px",
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    alignSelf: "end",
    justifyContent: "center",
    alignItems: "center",
  },
}));
