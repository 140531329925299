export const config = {
    apiKey: "AIzaSyCJovbvFyi1J7y04YO37TVfW6jsN1hQgUI",
    authDomain: "hisaab-7e8b4.firebaseapp.com",
    databaseURL: "https://hisaab-7e8b4.firebaseio.com",
    projectId: "hisaab-7e8b4",
    storageBucket: "hisaab-7e8b4.appspot.com",
    messagingSenderId: "170781208763",
    appId: "1:170781208763:web:0e2a98757ee1f59aeb399e",
    measurementId: "G-LN9T08NJG7"
};
