export default {
    LOGIN_VERIFICATION_CODE_TEXT: "ایک تصدیقی کوڈ بیحجا ہے اِس پر",
    LOGIN_COUNTRY_TEXT: "ملک چنیں / پاکستان (+92)",
    LOGIN_PHONENUMBER: "فون نمبر",
    WELCOME: "خوش امدیید",
    LOGIN: "لاگ ان",
    CHANGE_LANGUAGE: "زبان بدلیں",
    SELECT_LANGUAGE: " زبان چنیں",
    ADD_ACCOUNT: "نیا اکاؤنٹ شامل کریں",
    PAY: "ادا",
    DUE: "بقایہ",
    CLEAR: "مٹاؤ",
    PAID: "ادائیگی ہوگئی",
    ADDED_ON: "کو شامل کرا",
    TOOK_LOAN: "کا قرضہ لیا",
    RUPEES: "روپیہ",
    SEARCH_CONTACTS: "اکاؤنٹ تلاش کریں",
    SALAM: "سلام",
    NEW_CONTACT: "نیا اکاؤنٹ",
    GIVE_PAYMENT: "آپ نے دیئے",
    RECEIVE_PAYMENT: "آپ نے لیے",
    CHOOSE_YOUR_LANGUAGE: "اپنی زبان چنے",
    NEXT: "آگے",
    BACK: "پچھے",
    VERIFICATION_CODE: "تصدیقی کوڈ",
    A_verification_code_will_be_sent_on_your_number_via_SMS_Service_charges_may_apply: "ایک تصدیقی کوڈ آپ کے نمبر پر بھیجا جائے گا,س ایم س  سروس چارجز لاگو ہو سکتے ہیں",
    DIDNT_GET_CODE: "تصدیقی کوڈ نہیں ملا ؟",
    RESEND_CODE: "کوڈ دوبارہ بھیجیں",
    ENTER_MOBILE_NUMBER: "موبائل نمبر درج کریں",
    PAKISTAN_92: "پاکستان(92+)",
    PERSONAL_INFORMATION: "ذاتی معلومات",
    GET_STARTED: "شروع کریں",
    LOGOUT: "لاگ آؤٹ",
    HOME: "ہوم",
    ADD: "ایڈ",
    NO_ACCOUNT_FOUND_NAMED: "اِس نام کا کوئی اکاؤنٹ نہیں ہے",
    CONTACTS_FROM_PHONEBOOK: "فون بک کے کونٹیکٹس",
    SETTINGS: "سیٹنگز",
    ABOUT_US: "ہمارے متعلق",
    PRIVACY_POLICY: "رازداری کی پالیسی ( پرائی ویسی پالیسی )",
    HELP: "مدد",
    CONTACT: "رابطہ",
    SORT_NAME: "نام",
    SORT_AMOUNT: "رقم",
    SORT_LATEST: "تازہ ترین",
    SORT_PAYMENT_DUE: "مقررہ ادائیگی",
    NAME: "نام",
    PHONE_NUMBER: "فون نمبر",
    BUSINESS_NAME: "کاروبار کا نام",
    TODAY: "آج",
    YESTERDAY: "پچھلا دن",
    ENTER_AMOUNT: "راقم دالین",
    WRITE_NOTE_HERE: "یہاں نوٹ لکھیں",
    FULL_NAME: "پورا نام",
    EMAIL_ADDRESS: "ای میل اڈریس",
    BUSINESS_TYPE: "کاروبار کی قسم",
    TRANSACTION_INFO: "لین دین کی معلومات",
    SYNC_SUCESSFULLY: "اپ ڈیٹ کامیاب",
    NOT_SYNC: "اپ ڈیٹ ناکامیاب",
    SHARE_TRANSACTION: "لین دین (کی معلومات) بانٹیں",
    DELETE_TRANSACTION: "لین دین (کی معلومات) ڈلیٹ کریں",
    NOTE_NOT_SPECIFIED: "نوٹ واضع نہیں ہے",
    ADVANCE: "ایڈوانس",
    ACCOUNT: "اکاؤنٹ",
    WHATSAPP: "واٹس ایپ",
    SHARE: "شئیر",
    VERSION: "ورژن",
    UPDATE: "اپ ڈیٹ",
    UPDATE_ACCOUNT: "اکاؤنٹ اپ ڈیٹ",
    ACCOUNTS: "اکاؤنٹس",
    USER_STATEMENT: "صارف کی اسٹیٹمنٹ",
    PROFILE: "پروفائل",
    LANGUAGE: "زبان",
    BALANCE: "بیلنس",
    NOTE: "نوٹ",
    DELETE_ACCOUNT: "اکاؤنٹ ڈلیٹ",
    NET_BALANCE: "کُل بیلنس",
    CREDITS: "کریڈٹس",
    DEBITS: "ڈیبٹ",
    PAYMENTS: "ادائیگیاں",
    DO_YOU_WANT_TO_DELETE_THIS_TRANSACTION: "کیا آپ کو اِس لین دین کو ڈلیٹ کرنا ہے ؟",
    TRANSACTION_HAS_BEEN_DELETED: "لین دین ڈلیٹ ہوگئی",
    RESTORE_TRANSACTION: "لین دین بحال کریں",
    DO_YOU_WANT_TO_RESTORE_THIS_TRANSACTION: "کیا آپ اس لین دین بحال کرنا چاہتے ہیں ؟",
    TRANSACTION_HAS_BEEN_RESTORED: "لین دین بحال ہو گئی",
    VERIFIED_BY_CREDIT_BOOK: "کریڈٹ بک سے تصدیق شدہ",
    AMOUNT_GIVEN: "دی گئی رقم",
    AMOUNT_RECEIVED: "وصولی رقم",
    PLEASE_ENTER_AMOUNT: "برائے مہربانی رقم درج کریں",
    PLEASE_ENTER_MOBILE_NUMBER: "برائے مہربانی موبائل نمبر درج کریں",
    PLEASE_CHECK_INTERNET_CONNECTION: "برائے مہربانی انٹرنیٹ کنیکشن چیک کریں",
    SOMETHING_WENT_WRONG: "ارے! کچھ خرابی آئی ہے",
    CODE_RESENT: "کوڈ دوبار بھیج دیا",
    UPDATE_NOTE: "اپ ڈیٹ نوٹ",
    ADD_CUSTOMER: "ایڈ صارف",
    SEARCH_CUSTOMERS: "تلاش صارف",
    NEW_CUSTOMER: "نیا صارف",
    NO_CUSTOMER_FOUND_NAMED: "صارف نہیں ملا",
    UPDATE_CUSTOMER: "اپ ڈیٹ صارف",
    DELETE_CUSTOMER: "ڈلیٹ صارف",
    CUSTOMERS: "صارفیں",
    SEND_REMINDER: "یاد دہانی کروایین",
    SHARE_STATEMENT: "شیئر اسٹیٹمنٹ",
    CREDIT_LIMIT: "کریڈٹ کی حد",
    CONTACT_US: "ہم سے رابطہ",
    ADD_FROM_PHONEBOOK: "فون بک سے ایڈ",
    SEARCH_FROM_PHONEBOOK: "فون بک سے تلاش",
    NO_TRANSACTIONS_AVAILABLE: "کوئی لین دین موجود نہیں",
    TRANSACTION_MESSAGE_WHEN_GIVING: "اپنے قرض لیا ہےRs : {قیمت} , {دکان نام} سے, {تاریخ} کو, یہ بھیجا گیا ہے کریڈٹ بک سے. ابھی کریڈٹ بک ڈائون لوڈ کریں اور اپنی کھاتہ کتاب کو مووسار طریقه سے سامبحالیین",
    TRANSACTION_MESSAGE_WHEN_TAKING: "اپنے Rs : {قیمت} دیئے ہیں, {دکان نام} کو, {تاریخ} کو, یہ بھیجا گیا ہے کریڈٹ بک سے. ابھی کریڈٹ بک ڈائون لوڈ کریں اور اپنی کھاتہ کتاب کو مووسار طریقه سے سامبحالیین",
    SHARE_MSG: "  ابھی کریڈٹ بک ڈائون لوڈ کریں اور اپنی کھاتہ کتاب کو موثر سے سامبحالیین http://onelink.to/z8xrde",
    REMINDER_WHEN_CREDIT: "آپ کا کریڈٹ Rs: {اکاؤنٹ بیلنس} رہتا ہے {دکان نام} پر. کریڈٹ بک سے بھیجا گیا ہے. ابھی کریڈٹ بک ڈائون لوڈ کریں اور اپنی کھاتہ کتاب کو موثر سے سامبحالیین",
    REMINDER_WHEN_BALANCE: "آپ کا اکاؤنٹ بیلنس Rs : {اکاؤنٹ بیلنس} , {دکان نام} پر. ابھی کریڈٹ بک ڈائون لوڈ کریں اور اپنی کھاتہ کتاب کو موثر سے سامبحالیین",
    CONTACT_US_MESSAGE: "سلام ! مجھے مدد چائے کریڈٹ بک app کے استعمال میں",
    CHANGE_LANGUAGE_CONFIRMATION: "اِس خصوصیت کے لئے آپ کو آپ بند کرے کے ، دوبارہ شروع کرنا ہو گا . کیا آپ کرنا چاحتیی ہیں ؟",
    USER_CONSENT_MESSAGE: "سائن اپ کرکی آپ ہمارے شرائط و ضوابط اور رازداری کی پالیسی سے اتفاق رکھتے ہیں .",
    START_DATE: "تاریخ شروع",
    END_DATE: "تاریخ ختم",
    THIS_MONTH: "یہ مہینہ",
    CUSTOM_RANGE: "اپنے مرضی کے مطابق",
    CREDIT_LIMIT_EXCEED_MSG: "کریڈٹ حد سے تجاوز ، کیا آپ جاری رکھنا پسند کریں گے؟",
    WARNING: "خبردار",
    INVALID_MOBILE_NO_ERROR: "غلط موبائل # درست فورمیٹ : 03451234567",
    FIELD_REQUIRED: "فیلڈ درکار",
    CREDIT_LIMTI_CANT_BE_ZERO: "کریڈٹ کی حد صفر(0) نہیں ہوسکتی (اگر آپ حد مقرر کرنا نہیں چاہتے ہیں تو خالی چھوڑیں)",
    DO_YOU_WANT_TO_DELETE_THIS_CUSTOMER: "کیا آپ اس صارف کو ڈلیٹ کرنا چاہتے ہیں؟",
    ENTER_YOUR_NOTE_HERE: "اپنا نوٹ یہاں درج کریں",
    ADD_YOUR_FIRST_CUSTOMER: 'اپنا پہلا صارف داخل کریں .',
    PRESS_THE_BUTTON_FOR_ADDING_YOUR_FIRST_CUSTOMER: "اپنے پہلے صارف کو ایڈ کرنے کے لئے بٹن کو دبائیں .",
    GO_TO_DASHBOARD: "ڈیش بورڈ پر جائیں",
    TOOLTIP_TEXT_ONBOARDING_WHEN_ADDED_FIRST_CUSTOMER: "آپ نے اپنا پہلا صارف درج کر لیا ہے ! پیسو کی وصولی یا ادائیگی کے لیے صارف پر کلک کریں.",
    TOOLTIP_TEXT_ONBOARDING_WHEN_ADDED_FIRST_TRANSACTION: 'آپ نے لین دین درج کر لی ہے! CreditBook میں اور بھی خصوصیات موجود ہیں مزید جاننے کے لئے آئی کن (?) پر کلک کریں مددگار ویڈیوز دیکھنے کے لیے',
    REMINDER_HAS_BEEN_SENT: "یاد دہانی کروادی",
    MOBILE_NO_NOT_ADDED: "موبائل نمبر موجود نہیں",
    MOBILE_NO_NOT_ADDED_DESCRIPTION: "کا موبائل نمبر موجود نہیں . کیا آپ موبائل نمبر درج کرنا پسند کرینگے ؟ ",
    SOMETHING_WENT_WRONG_WHILE_SENDING_SMS: "ایس ایم ایس بھیجتے وقت کوئی خرابی آئی ہے۔ بارے مہربانی دوبارہ کوشش کیجئے!",
    REPORTS: "رپورٹیں",
    REMINDERS: "یادہانی",
    PAYMENT_DUE_DATE: "ادائیگی کی آخری تاریخ",
    ACOUNT_CALL: 'کال',
    ACCOUNT_PAYMENT_REMINDER_DATE_SET: 'ادائیگی کی یادہانی کی تاریخ درج کریں',
    AUTO_REMINDER: "خُود کار یادہانی",
    REMINDER_DATE: "یادہانی کی تاریخ",
    FREE_SMS: "مفت ایس ایم ایس",
    REMIND_ALL: "سب کو ياد دلائیں",
    REMIND: "یاد دلائیں",
    REMINDER_SENT: "یادہانی بھیج دی گئی",
    SMS_ABOUT_TO_SEND_ALL: "آپ ایس ایم ایس بھیجنے والے ہیں، سارے اُن صارفین کو جنہوں نے بقیہ رقم دینی ہے! کیا آپ جاری رکھنا چاہینگے؟ نوٹ : ایس ایم ایس صرف اُن صارفین کو بھیجا جائیگا جن کا نمبر درج ہے۔",
    APPLY_FILTERS: "فلٹرز چلائیں",
    LOWEST_AMOUNT: "کم ترین رقم",
    HIGHEST_AMOUNT: "سب سے زیادہ رقم",
    SORT_BY: "ترتیب بذریعہ",
    AMOUNT: "رقم",
    ADD_FILTERS: "فلٹرز لگائیں",
    TRANSACTIONS_ALERT_SETTING: "لین دین اطلاع سیٹنگز",
    TRANSACTION_ALERTS: "لین دین اطلاعات",
    TRANSACTION_TOGGLE_MESSAGE: "لین دین اطلاعات، ایس ایم ایس کے بذریعہ آپ کے صارف کو جاتی ہے جب بھی لین دین عمل میں آتی ہے",
    DATA_SECURITY_MESSAGE: "آپ کا ڈیٹا اور لین دین مکمل طور پر محفوظ ہیں",
    AUTO_REMINDER_MESSAGES: "ہر روز صبح کے 6 بجے یادہانی خود بہ خود بھج دی جاتی ہے",
    SCHEDULED_FOR_LATER: "بعد کے لئے مقرر",
    TO_BE_SENT: "بھیجنا ہے",
    APPLOCK: "ایپ لاک",
    CREATE_A_NEW_PIN: "نئی پن بنائیں",
    CONFIRM_PIN: "تصدیق پن",
    PIN_NOT_MATCHED_ERROR: "پن میں مماثلت نہیں برائے مہربانی دوبارہ درج کریں",
    APPLOCK_ENABLED: "ایپ لاک لگا دیا",
    APP_LOCK_UPDATED: "ایپ لاک اپ ڈیٹڈ",
    REMOVE_APPLOCK: "ایپ لاک ہٹائیں",
    CHANGE_PIN: "پن تبدیل",
    RE_ENTER_YOUR_PIN: "پن دوبارہ درج کریں",
    REMOVE_PIN: "پن ہٹائیں",
    INVALID_PIN_ERROR: "غلط پن براہ مہربانی دوبارہ درج کریں",
    APP_LOCK_REMOVED: "ایپ لاک ہٹ گیا",
    ENTER_YOUR_OLD_PIN: "پرانی پن درج کریں",
    ENTER_YOUR_PIN: "پن درج کریں",
    CONTACT_SUPPORT: "رابطہ کریں مدد کیلئے",
    FORGOT_YOUR_PIN: "پن بھول گئے",
    CLICK_HERE: "کلک یہاں",
    DATA_BACKUP: "ڈیٹا بیک اپ",
    YOUR_DATA_HAS_BEEN_BACKED_UP: "آپ کا ڈیٹا بیک اپ ہو گیا",
    DATA_BACKUP_DESC: "انٹرنیٹ سے کنیکٹ کریں اپنے ڈیٹا کو ہمارے سرورز پر بیک اپ کرنے کیلئے",
    LAST_BACKUP_AT: "آخری بیک اپ ہوا",
    SMS_ALERT_LANGUAGE: "ایس ایم ایس الرٹ زبان",
    TRANSACTIONAL_SMS_SAMPLE: "لین دین کے ایس ایم ایس کی مثال",
    CONFIRM_LANGUAGE: "زبان کنفرم",
    TOTAL: "کُل",
    CUSTOMER_STATEMENT: "صارف سٹیٹمنٹ",
    ALL: "سب",
    BUSINESS_CARD: "بزنس کارڈ",
    SAMPLE_CARD_DESIGN: "عام کارڈ ڈیزائن",
    CREATE_A_NEW_BUSINESS_CARD: "نیا بزنس کارڈ بنائیں",
    ALTERNATE_PHONE_NUMBER: "متبادل فون نمبر",
    LOCATION: "لوکیشن",
    AUTOMATICALLY_PICKS_UP_CURRENT_LOCATION_SHOWN_IN_GOOGLE_MAP_BELOW: "نیچے دی گئی لوکیشن خود بہ خود گوگل میپ کے ذریعے چن لیگا",
    SAVE_CARD: "سیو کارڈ",
    SHARE: "شیئر",
    DELETE: "ڈیلیٹ",
    EDIT: "ایڈٹ",
    CREATE_NEW_CARD: "نیا کارڈ بنائیں",
    COLLECT_PAYMENTS_3_TIMES_FASTER: "ادائیگی 3 گھنہ تیزی سے حاصل کریں!",
    SET_COLLECTION_DATES: "تاریخ طے کریں حاصل کرنے کی",
    REMINDERS_GO_OUT_1_DAY_BEFORE: "یادہانی ایک دن پہلے کروادی جائے گی",
    COLLECT_PAYMENTS_3_X_FASTER: "ادائیگی 3X تیزی سے حاصل کریں!",
    ADDRESS: "پته",
    BUSINESS_CARD_HAS_BEEN_CREATED: "بزنس کارڈ بنگیا!",
    BUSINESS_CARD_HAS_BEEN_UPDATED: "بزنس کارڈ اپڈیٹ ہوگیا!",
    DATE_RANGE: "ڈاٹا رینج",
    INVALID_AMOUNT: "غلط رقم",
    COLLECTION_DATE: "جمع کرنے کی تاریخ",
    YOUR_DATA_IS_100_SAFE_AND_SECURE: "آپ کا ڈیٹا 100٪ بہ حفاظت اور محفوظ ہے",
    TRANSACTION_HAS_BEEN_UPDATED: "لین دین اپڈیٹ ہوگئی",
    MORE: "مزید",
    PROMOTE: "پھلانا",
    ADVERTISE_YOUR_PRODUCTS: "اپنے پروڈکٹس کا اشتہار",
    ADVERTISE_YOUR_PRODUCTS_DESC: "آج کی خصوصی پروموشن كا اپنے صارفین کو بتائیں WhatsApp کے زریعے!",
    CREATE_A_NEW_AD: "نیا اشتہار بنائیں",
    STEP_1: "پہلا قدم",
    TAKE_OR_UPLOAD_A_PICTURE_OF_THE_PRODUCT: "جس پروڈکٹ كو آپ پروموٹ کرنا چاہتے ہیں اُس کی تصویر لین یا اپلوڈ کریں اور کیپشن لکھیں",
    TAK_UPLOAD_A_PICTURE: "تصویر لیں / اپلوڈ",
    CREATE_A_NEW_AD_POST_TO_SHARE: "شیئر کرنے کے لئے ایک نئے اشتہاری پوسٹ بنائیں",
    THIS_IS_WHATSAPP_TEXT_MESSAGE: "یہ WhatsApp ٹیکسٹ میسج ہے",
    STEP_2: "دوسرا قدم",
    DO_YOU_WANT_TO_SEND_AN_ACCOMPANYING_MESSAGE: "کیا آپ ساتھ میں ایک پیغام بھیجنا چاہتے ہیں؟",
    PENDING: "زیر غور",
    UPCOMING: "آئندہ",
    COLLECTION_DATE_NOT_SET: "جمع کرنے کی تاریخ مقرر نہیں ہے",
    SELECT_DATE: "تاریخ منتخب کریں",
    SEND_REMINDER: "یاد دہانی بھیجیں",
    SELECT_ON_MAP: "نقشے پر منتخب کریں",
    LOCATION_UPDATED: "جگہ اپڈیٹ ہوگئی",
    WELCOME_TO_CREDITBOOK: "کریڈٹبُک میں خوش آمدید",
    MANAGE_FINANCES_TRACK_PAYMENTS: "مال کو سنبھالیں۔ ادائیگی ٹریک کریں۔ اپڈیٹ رسیوابلز۔ اب ادھار ہوگیا ڈیجیٹل!",
    LETS_GO: "چلو!",
    KHATA_AB_PHONE_PE: "کھاتہ اب فون پّے!",
    STAY_INFORMED_AND_BE_IN_CONTROL_OF_CREDIT: "مطالعہ رہیں اور صارفین اور سپلائرز کے ساتھ کریڈٹ کی لین دین نظر میں رکھیں۔",
    YOUR_DATA_IS_SAFE_AND_SECURED: "آپ کا ڈاٹا با حفاظت اور محفوظ ہے",
    TEXT_ABOUT_CLOUD_SECURITY_GOES_HERE: "کلاؤڈ سیکیورٹی کے بارے میں پیغام یہاں اور یہاں آتے ہیں",
    ASAAN_HISAAB_KITAAB_ACCOUNTS: "آسان حساب کتاب اکاؤنٹس منٹس میں۔ ڈھیر سارے پیپرز سے گزرنا اور صارفین سے بحث کرنا اب اور نہیں۔",
    SKIP: "چھوڑدیں",
    TITLE: "ٹائٹل",
    ADD_YOUR_CAPTION_HERE: "کیپشن یہاں لکھیں",
    DONE: "ہوگیا",
    AD_INSERTED_SUCCESS: "اشتہار لگ گیا!",
    ONLINE_NOTE: "آپ آنلائن موڈ میں ایپ استمال کر رہے ہیں!",
    OFFLINE_NOTE: "آپ آفلین موڈ میں ایپ استمال کر رہے ہیں!",
    TITLE_IS_REQUIRED: "ٹائٹل ضروری ہے!",
    AD_UPDATED_SUCCESS: "شتہار اپڈیٹ!",
    CAROUSEL_DESC: "روزآنہ اپنے کاروباری اکاؤنٹ کو اپڈیٹ کرکے اپنا ٹائم بچائے۔ کسٹمر سے بحث اور پیپرز میں ٹائم ضیاء کرنا اب بلکل نہیں۔",
    EXISTING_CUSTOMER: "موجودہ کسٹمر",
    ADD_A_STARTING_BALANCE: "ابتدائی بیلنس ڈالیں",
    STARTING_BALANCE: "ابتدائی بیلنس",



















}