import { makeStyles } from "@material-ui/core/styles"
import { themeStyleSheet, themeColors } from '../../../constants'

const useStyles = makeStyles((theme) => ({
    resendCodeText: {
        fontWeight: '1rem',
        fontWeight: 700,
        marginleft: "4px",
        color: (theme) =>
            theme.count === 0 ? themeColors.appColor : themeColors.loginMobilePlaceholder,
        disabled: "true",
        "&:hover": {
            color: (theme) => theme.count === 0 && themeColors.loginMobilePlaceholder,
            cursor: (theme) => theme.count === 0 ? "pointer" : "auto"
        },
        "&:active": {
            color: (theme) => theme.count === 0 && themeColors.loginMobilePlaceholder
        },
    }
}))

export default useStyles;
