import { CircularProgress } from "@material-ui/core";
import React, { memo } from "react";
import { themeColors } from "../../../constants";
import useStyles from "./styles";

const Loader = ({ loading }) => {
  const styles = useStyles();
  if (loading)
    return (
      <div classNames={styles.disableParent}>
        <div className={styles.overaly}>
          <CircularProgress style={{ color: themeColors.appColor }} />
        </div>
      </div>
    );
  return null;
};

export default Loader;
