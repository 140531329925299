import { AppBar, Toolbar, Typography, Icon, Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import useStyles from "./styles";
import { formatNum, themeColors } from "../../../constants";

const Header = ({
  title,
  subTitle,
  titleIcon,
  titleFunc,
  titleAvatar,
  rightIcon1,
  rightIcon1Func,
  rightIcon2,
  rightIcon2Func,
  Dashboard,
  data,
  image,
  text,
}) => {
  const [balanceString, setBalanceString] = useState("");
  const [balance, setBalance] = useState("");
  const [textColor, setTextColor] = useState("");
  const TextTypography = withStyles({
    root: {
      color: "#626262",
    },
  })(Typography);

  useEffect(() => {
    const creditAmount = data
      ?.filter((val) => val?.transaction_type === "credit")
      .reduce((acc, curr) => parseInt(acc) + parseInt(curr?.amount), 0);

    const debitAmount = data
      ?.filter((val) => val?.transaction_type === "debit")
      ?.reduce((acc, curr) => parseInt(acc) + parseInt(curr?.amount), 0);

    if (debitAmount > creditAmount) {
      setBalanceString(`${text}, aap ne dene hain: `);
      setBalance(`Rs. ${formatNum(Math.abs(debitAmount - creditAmount))}`);
      setTextColor(themeColors.redColor);
    } else {
      setBalanceString(`${text}, aap ne lene hain: `);
      setBalance(`Rs. ${formatNum(Math.abs(creditAmount - debitAmount))}`);
      setTextColor(themeColors.appColor);
    }
  }, [data, text]);

  const styles = useStyles();
  return (
    <AppBar position="static" className={styles.headerContainer}>
      <Toolbar className={styles.toolBarContainer}>
        <div className={styles.headingContainer}>
          {Dashboard ? null : (
            <Avatar style={{ marginRight: "5px", backgroundColor: "#D3D3D3" }}>
              {image ? (
                <img src={image} width="50" height="50" alt="avatar" />
              ) : (
                titleAvatar
              )}
            </Avatar>
          )}

          <div className={styles.headingContainer} onClick={titleFunc}>
            {!titleIcon && (
              <>
                <Avatar
                  style={{ marginRight: "5px", backgroundColor: "#D3D3D3" }}
                >
                  {titleAvatar}
                </Avatar>
              </>
            )}
            <div className={styles.titleContainer}>
              <Typography className={styles.title}>{title}</Typography>
              <Typography className={styles.subTitle}>
                {subTitle && subTitle.substring(0, 4)}{" "}
                <span>{subTitle && subTitle.substring(4, 11)}</span>
              </Typography>
            </div>
          </div>
        </div>
        <div>
          <Icon style={{ paddingRight: "40px" }} onClick={rightIcon1Func}>
            {rightIcon1}
          </Icon>
          <Icon onClick={rightIcon2Func} style={{ paddingRight: "7px" }}>
            {rightIcon2}
          </Icon>
        </div>
      </Toolbar>
      <Toolbar className={styles.toolBarContainer2}>
        <div className={styles.headingContainer}>
          <TextTypography className={styles.text}>
            {title ? balanceString : `, aap ne dene hain: Rs.`}
            <span
              variant="body1"
              style={{ color: textColor, fontWeight: "bold" }}
            >
              {title ? balance : ``}
            </span>
          </TextTypography>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
