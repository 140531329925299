import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    gap: "5rem",
  },

  footerText: {
    marginTop: "2px",
    fontSize: "12px",
    fontWeight: "600",
    paddingLeft: "4px",
  },
  footerSection1: {
    display: "flex",
    flex: 1.5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "24px",
  },
  footerSection2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingRight: "24px",
  },
  PlayStoreLogo: {
    marginTop: "8px",
  },
}));

export default useStyles;
