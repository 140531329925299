import { makeStyles } from "@material-ui/core/styles";
import { themeColors } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: themeColors.appColor,
    //position: "fixed",
  },
  toolBarContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  toolBarContainer2: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
  },

  headingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    textOverflow: "ellipsis",
    fontFamily: "GilmerBold",
    fontSize: "16px",
    lineHeight: "24px",
  },
  text: {
    fontSize: "14px",
    textOverflow: "ellipsis",
    lineHeight: "24px",
  },
  subTitle: {
    fontSize: "12px",
    fontWeight: "400",
    textOverflow: "ellipsis",
    lineHeight: "16px",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export default useStyles;
