import { Grid, Typography } from "@material-ui/core";
import { useState, useEffect } from "react";
import ScrollArea from "react-scrollbar";
import {
  Header,
  TransactionContainer,
  TransactionalFooter,
  SharedLedgerFooter,
} from "./components";
import Loader from "./components/common/Loader";
import useStyles from "./styles";
import axios from "axios";
import Vector from "../src/assets/Vector.png";
import { TransactionalDetailsModal } from "./components/common/TransactionDetailsModal";
import { SegmentAnalytics, formatPhoneNumber } from "./utils";
import { LedgerType, TransactionTypes } from "./constants";

const App = () => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState();
  const [transactions, setTransactions] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [transaction, setTransaction] = useState();
  const [ownerName, setOwnerName] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isSharedLedger, setIsSharedLedger] = useState(false);
  const [scrollDisabled, setScrollDisabled] = useState(false);
  const [isStagingEnv, setIsStagingEnv] = useState(false);


  const fireSlWebViewEvent = async (customer) => {
    try {
      const credit = customer.credit ? customer.credit : 0;
      const debit = customer.debit ? customer.debit : 0;

      const response = await fetch('https://api.ipify.org/', {
          timeout: 2000, //2 second timeout
      });
      const ip = await response.text();

      SegmentAnalytics.track({
        event: "viewed_sharedLedgerWebView",
        properties: {
          customer_id: customer?.firestore_id,
          totalCredit: credit,
          totalDebit: debit,
        }
      }, {
        ip,
      })
    } catch (err) {
      console.error(err);
    }

  };

  useEffect(() => {
    setTimeout(() => {
      setScrollDisabled(true);
    }, 1000);

    // Getting all query params from the url
    const urlMessage = window.location.pathname.substring(1);
    const routeParams = urlMessage.split("&");

    let id = routeParams[0];
    let isStaging = routeParams[1] === "staging"; //since params are always retrieved as string
    setIsStagingEnv(isStaging);


    if (!id) {
      const queryString = window.location.search.substring(1);
      const paramsStr = queryString.split("&");

      const queryParams = {};

      paramsStr.forEach((paramString) => {
        const pair = paramString.split("=");
        queryParams[pair[0]] = pair[1];
      });

      id = queryParams["id"];
      isStaging = queryParams["staging"] === "true"; //since query params are always retrieved as string
      setIsStagingEnv(isStaging);
    }


    const apiUrl = isStaging
      ? process.env.REACT_APP_API_URL_STAGE
      : process.env.REACT_APP_API_URL_PROD;

    setLoading(true);
    if (!id) {
      setLoading(false);
      setNotFound(true);
      return;
    };

    const URL = `${apiUrl}/api/v1/customer/tx`;

    axios
      .get(URL, {
        params: {
          customer_id: id,
        },
      })
      .then((res) => {
        if (res?.data?.customer?.ledger_type === LedgerType.SHARED) {
          setIsSharedLedger(true);
          fireSlWebViewEvent(res?.data?.customer);
        }
        setTransactions(res?.data?.transactions);
        setCustomer(res?.data?.customer);

        setOwnerName(res?.data?.owner);
        setImgUrl(res?.data?.ownerImage);
        setLoading(false);
      })
      .catch((err) => {
        console.error("catch error", err);
        setLoading(false);
        setNotFound(true);
      });
  }, []);

  return (
    <div className={styles.container}>
      <Loader loading={loading} />
      <Header
        title={ownerName}
        titleIcon={true}
        subTitle={formatPhoneNumber(customer?.customer_owner_mobile_no)}
        titleAvatar={ownerName[0]}
        data={transactions}
        image={imgUrl}
        text={customer?.name}
      />
      <ScrollArea
        onScroll={() => {
          if (isSharedLedger && scrollDisabled) {
            setShowModal(true);
            setScrollDisabled(false);
          }
        }}
        autoHide
        autoHeight
        autoHeightMax={"100%"}
        autoHideDuration={100}
        autoHideTimeout={500}
        renderThumbVertical={() => <div className={styles.thumbStyle}></div>}
      >
        <div style={{ overflowX: "hidden", paddingBottom: "80%" }}>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            direction="row"
          >
            {!notFound && (
              <Grid
                container
                item
                alignItems="center"
                justifyContent="center"
                className={styles.transactionAlertDesc}
              >
                <img src={Vector} alt="eye" />
                {isSharedLedger ? (
                  <Typography className={styles.transactionAlertDescText}>
                    {`Yeh aapka aur ${
                      ownerName.split(" ")[0]
                    } ka Joint Khata hai`}
                  </Typography>
                ) : (
                  <Typography className={styles.transactionAlertDescText}>
                    {customer?.name
                      ? `Aap ${ownerName.split(" ")[0]} ka khata dekh rahe hain`
                      : `Aap ka khata dekh rahe hain`}
                  </Typography>
                )}
              </Grid>
            )}
            {notFound && (
              <Grid
                container
                item
                alignItems="center"
                // direction="row"
                justifyContent="center"
                className={styles.transactionAlertDesc}
              >
                <Typography className={styles.transactionAlertDescText}>
                  {"404 khata not found"}
                </Typography>
              </Grid>
            )}
          </Grid>
          {transactions?.map((val, ind) => {
            return (
              <TransactionContainer
                transaction={val}
                index={ind}
                transactions={transactions}
                isSharedLedger={isSharedLedger}
                titleAvatar={ownerName[0]}
                sharedCustomerAvatar={customer?.name[0]}
                setShowModal={setShowModal}
                imgUrl={imgUrl}
                fromStatement={true}
                openModal={(val) => {
                  setTransaction(val);
                  setOpenModal(true);
                }}
                key={ind}
              />
            );
          })}
        </div>
      </ScrollArea>
      <TransactionalDetailsModal
        showModal={openModal}
        isStagingEnv={isStagingEnv}
        closeModal={() => setOpenModal(false)}
        transaction={transaction}
        customer={customer}
        ownerMobileNumber={formatPhoneNumber(
          customer?.customer_owner_mobile_no
        )}
      />
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          border: "2px solid #FFFFFF",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
          backgroundColor: "#FFFFFF",
        }}
      >
        {isSharedLedger ? (
          <SharedLedgerFooter
            ownerName={ownerName}
            customerId={customer?.firestore_id}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        ) : (
          <TransactionalFooter />
        )}
      </div>
    </div>
  );
};

export default App;
