import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  createTransactionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: "24px",
    backgroundColor: "#F6F4F0",
  },
  youGotButton: {
    backgroundColor: "#25D366",
    color: "#FFFFFF",
    textTransform: "capitalize",
    flex: 0.45,
    height: "48px",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "3px",
  },
  youGaveButton: {
    backgroundColor: "#D22D2F",
    color: "#FFFFFF",
    textTransform: "capitalize",
    flex: 0.45,
    height: "48px",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "3px",
  },
}));

export default useStyles;
