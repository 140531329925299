import { makeStyles } from "@material-ui/core/styles"
import { themeColors } from "../../../constants"

const useStyles = makeStyles((theme) => ({
    amountContainer: {
        width: "20%"
    },
    timeDateContainer: {
        borderLeft: "2px solid #D3D3D3",
        borderRight: "2px solid #D3D3D3",
        paddingLeft: "5px",
        paddingRight: "5px"
    },
    reportContainer: {
        borderBottom: "1px solid",
        marginLeft: "10px",
        cursor: "pointer"
    },
    reportButton: {
        backgroundColor: themeColors.amountDue,
        color: "white",
        marginTop: "10px ",
        alignSelf: "flex-end",
        width: "120px",
    }



}))

export default useStyles